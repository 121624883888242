export const TABLA_DOCUMENTOS = `
    query filterDocumentos($filtro: InputFilterDocumento!) {
        filterDocumentos(input: $filtro) {
            registros
            paginas
            listado {
                empresaID
                doctoID
                tipoDoctoID
                tipoComprobante
                rfcEmisor
                cpEmisor
                claveRegimenEmisor
                rfcReceptor
                cpReceptor
                claveRegimenReceptor
                nombreEmisor
                nombreReceptor
                uuid
                serie
                folio
                fecha
                fechaTimbrado
                versionTimbrado
                estadoSAT
                subtotal
                IVA
                total
                versionTimbrado
                xmlCFDI
            }
        }
    }`;

export const GET_DOCUMENTOS = `
query getDocumentos($filtro: InputGetDocumento){
    getDocumentos(filtro: $filtro){
        doctoID
        rfcEmisor
        rfcReceptor
        total
        uuid
    }
}`;

export const GET_ALL_DOCUMENTOS = `
query getAllDocumentos($filtro: InputGetDocumento){
    getAllDocumentos(filtro: $filtro){
        doctoID
        tipoDoctoID 
        rfcEmisor
        rfcReceptor
        divisa
        estadoVigencia
        fechaInicio 
        fechaFin
    }
}`;

export const GET_ALL_TIPOSDOCUMENTOS = `
query GetAllTipoDocto($emisor: Int) {
    GetAllTipoDocto(emisor: $emisor) {
        tipoDoctoID            
        tipoDocto         
        tipoComprobante           
        Emisor  
    }
}
`;

export const GET_ALL_DIVISAS = `
query GetAllDivisas($id: Int) {
    GetAllDivisas(id: $id) {
        id
        divisa
    }
}
`;

export const GET_ALL_ESTADO_VIGENCIA = `
query GetAllEstadoVigencia($id: Int) {
    GetAllEstadoVigencia(id: $id) {
        id
        estadoVigencia
    }
}
`;
