import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { useTokenValido } from '../../hooks/sesion';

import Menu from '../Menu';
import Login from '../Login';
import Preloder from '../../_layout/genericComponents/Preloader';

function Inicio() {

    const hasToken = useTokenValido();

    return( hasToken === null ? <Preloder /> : (hasToken ? <Menu /> : <><Switch><Redirect from={"*"} to={"/"} /></Switch><Login /></>) );

}

export default Inicio;
