import React, { useState, useRef, useEffect } from 'react'

// generic components
import SweetAlert from '../../genericComponents/ModalConfirmacion';
import Formulario from '../../genericComponents/Formulario';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SendIcon from '@material-ui/icons/Send';
// import HelpIcon from '@material-ui/icons/Help';
import ReplayIcon from '@material-ui/icons/Replay';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EmailIcon from '@material-ui/icons/Email';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import FaceIcon from '@material-ui/icons/Face';

import styles from "../../assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

import { asyncHandleFocus, handleFocus, focusValidacion } from '../../helpers/handles';
import { info, warning, primary, success, danger } from '../../helpers/colores';
import { Encriptar } from '@renedelangel/helpers';

const useStyles = makeStyles(styles);
const { DescriptarToken } = Encriptar;

function Login({ title, isUsername, getLogin, getNewPassword, setToken, setUsuario, loginFondo, seedBackend }) {

    const classes = useStyles();

    const { usuario } = useSelector(state => state.login);

    const dispatch = useDispatch();
    const setTokenRedux = (token) => dispatch(setToken(token));
    const setUsuarioRedux = (usuario) => dispatch(setUsuario(usuario));

    const cleanDisabled = { username: false, password: true, acceder: true };
    const cleanSesion = { username: "", password: "" };
    const cleanLoader = { acceder: false, cambiarPassword: false };
    const cleanErrorState = {
        username: { error: false, helperText: ""  },
        password: { error: false, helperText: ""  }
    };

    const [sesion, setSesion] = useState(cleanSesion);
    const [ver, setVer] = useState(false);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [loader, setLoader] = useState(cleanLoader);
    const [disabled, setDisabled] = useState(cleanDisabled);
    const [alert, setAlert] = useState(null);

    const grid = { xs:12, sm: 12, md: 5, lg: 4 };

    let usernameRef = useRef(null);
    let passwordRef = useRef(null);
    let accederRef = useRef(null);

    const iconoPassword = {
        onClick: () => !disabled.password && setVer(ver => !ver),
        style: { cursor: "pointer" }
    };

    const inputs = [{
        disabled: disabled.username,
        id: "username",
        value: sesion.username,
        error: errorState.username.error,
        success: sesion.username && !errorState.username.error ? true : undefined,
        helperText: errorState.username.helperText,
        title: "Nombre de usuario",
        placeholder: "Capture su nombre de usuario",
        icono: <EmailIcon />,
        inputRef: usernameRef,
        onChange: ({target:{value}}) => handleSesion(value, "username"),
        onKeyDown: ({key, keyCode}) => (key === "Enter" || keyCode === 13) && ftVerificaUsername(),
        inputProps: { onBlur: ftVerificaUsername }
    }, {
        disabled: disabled.password,
        id: "password",
        value: sesion.password,
        error: errorState.password.error,
        success: sesion.password && !errorState.password.error ? true : undefined,
        helperText: errorState.password.helperText,
        title: "Contraseña",
        placeholder: "Capture su contraseña",
        icono: disabled.password ? <VpnKeyIcon /> : (ver ? <VisibilityOffIcon { ...iconoPassword } />  : <VisibilityIcon { ...iconoPassword }/>),
        inputRef: passwordRef,
        inputProps: { type: ver ? "text" : "password", onBlur: ftIniciarSesion },
        onChange: ({target:{value}}) => handleSesion(value, "password"),
        onKeyDown: (evt) => asyncHandleFocus(evt, accederRef, false, ftIniciarSesion)
    }];

    const acciones = [{
        loader: loader.acceder,
        disabled: disabled.acceder,
        icono: ArrowRightIcon,
        color: info,
        descripcion: "Acceder",
        onClick: () => console.log("iniciando"),
        inputRef: accederRef
    },{
        disabled: disabled.password,
        icono: ReplayIcon,
        color: warning,
        descripcion: "Cambiar de usuario",
        onClick: () => asyncHandleFocus({key: "Enter", keyCode: 13}, usernameRef, true, async () => {
            setUsuarioRedux(null);
            setDisabled(cleanDisabled);
            setErrorState(cleanErrorState);
            setSesion(cleanSesion);
            setLoader(cleanLoader);
        })
    },{
        loader: loader.cambiarPassword,
        disabled: disabled.password,
        icono: SendIcon,
        color: primary,
        descripcion: "Recuperar contraseña",
        onClick: ftRecuperarPassword,
        // inputRef: accederRef
    }];

    function ftIniciarSesion() {
        async function iniciarSesion(){
            setLoader({ ...loader, acceder: true });
            try {
                setDisabled(disabled => ({
                    ...disabled,
                    password: true
                }));
                const token = await getLogin({ username: sesion.username, password: sesion.password });
                if(!token) throw new Error("No se pudo obtener el token");
                let { usuario } = await await DescriptarToken({ token, seed: seedBackend });
                setUsuarioRedux(usuario);
                setTokenRedux(token);
                setErrorState(cleanErrorState);
            } catch({message:helperText}) {
                setDisabled(disabled => ({
                    ...disabled,
                    password: false
                }));
                setErrorState(errorState => ({
                    ...errorState,
                    password: { helperText, error: true }
                }));
                setLoader(cleanLoader);
            }
        } iniciarSesion();
    }

    function ftVerificaUsername() {
        async function verificaUsername(){
            setLoader({ ...loader, acceder: true });
            try {
                setDisabled(disabled => ({
                    ...disabled,
                    username: true
                }));
                const isValid = await isUsername({ username: sesion.username });
                if(!isValid) throw new Error("No fue posible válidar al usuario capturado");
                setErrorState(cleanErrorState);
                setDisabled(disabled => ({
                    ...disabled,
                    password: false
                }));
                focusValidacion({ referencia: passwordRef });
            } catch({message:helperText}) {
                setDisabled(cleanDisabled);
                setSesion(sesion => ({
                    ...sesion,
                    password: ''
                }))
                setErrorState(errorState => ({
                    ...errorState,
                    username: { helperText, error: true }
                }));
            }
            setLoader(cleanLoader);
        } verificaUsername();
    }

    function ftRecuperarPassword() {
        async function recuperarPassword() {
            setLoader({ ...loader, cambiarPassword: true });
            setDisabled(disabled => ({
                ...disabled,
                password: true
            }));
            try {

                await getNewPassword({ username: sesion.username });

                setAlert({
                    descripcion: "Te hemos enviado un correo para que puedas cambiar tu contraseña, este correo solo te permite cambiar la contraseña durante los próximos 5 minutos",
                    title: "¡Revisa tu correo!",
                    tipo: success,
                    msjConfirmacion: "¡Gracias!",
                    onConfirm: () => setAlert(null)
                });

            } catch({message}) {
                setAlert({
                    descripcion: message,
                    title: "¡Te pedimos una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "De acuerdo",
                    onConfirm: () => setAlert(null)
                });
            }
            setDisabled(disabled => ({
                ...disabled,
                password: false
            }));
            setLoader(cleanLoader);
        } recuperarPassword();
    }

    function handleSesion(value, id) {
        setSesion(sesion => ({
            ...sesion,
            [id]: value
        }));
    }

    function ftEffect() {
        if(usuario && usuario.email) {
            handleSesion(usuario.email, "username");
            setErrorState(cleanErrorState);
            setDisabled(disabled => ({
                ...disabled,
                username: true,
                password: false
            }));
            handleFocus({ key: "Enter", keyCode: 13 }, passwordRef);
        }
    }

    useEffect(ftEffect, [usuario]);

    return (<div
        className={classes.fullPage}
        style={{ backgroundImage: "url(" + loginFondo + ")" }}
    >
        <Formulario
            title={title}
            icono={<LockOpenIcon />}
            grid={grid}
            inputs={inputs}
            acciones={acciones}
            focus={usernameRef}
            color={primary}
            // info={{
            //     message: "Después de capturar su nombre de usuario o contraseña, presione la tecla Enter/Intro o Tab",
            //     icon: HelpIcon,
            //     color: primary
            // }}
            otraInfo={(usuario && usuario.nombreUsuario) && [{
                titulo: usuario.nombreUsuario,
                descripcion: `Parece que tu sesión ha finalizado, si el usuario capturado no es el tuyo elige la opción: "Cambiar de usuario"`,
                icono: FaceIcon,
                colorIcono: "primary"
            }]}
        />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        /> }
    </div>);

}

export default Login;
