import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';
import { IS_USERNAME, GET_LOGIN, IS_TOKEN, GET_NEWPASSWORD } from '../index';

export const isUsername = async (variables, token) => {
    try {

        let { isUsername } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: IS_USERNAME
        });

        return isUsername;

    } catch({message}) { throw new Error(message); }
}

export const getLogin = async (variables, token) => {
    try {

        let { getLogin } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_LOGIN
        });

        return getLogin;

    } catch({message}) { throw new Error(message); }
}

export const isToken = async (token) => {
    try {

        let { isToken } = await FetchAPI.graphql({
            token,
            url: urlServidor,
            query: IS_TOKEN
        });

        return isToken;

    } catch({message}) { throw new Error(message); }
}

export const getNewPassword = async (variables, token) => {
    try {

        let { getNewPassword } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_NEWPASSWORD
        });

        return getNewPassword;

    } catch({message}) { throw new Error(message); }
}
