import React, { useState, useEffect } from "react";
import './verXml.css';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Modal from '../../_layout/genericComponents/Modal';

function VerXML(props) {
  let { xml, ocultar = () => { return } } = props;

  const [dataXML, setDataXML] = useState([]);
  const [infoXML, setInfoXML] = useState([]);

  let nodosXID = [];
  function dataToXML() {
    let parser = new DOMParser();
    let objXml = parser.parseFromString(xml, "application/xml"); 
    let objDatos = xmlToObj(objXml.children);
    setDataXML(objDatos);
    return objDatos;
  }

  function xmlToObj(nodos, id = 1) {
    let items = [];
    let auxNodo = {};
    for (let i = 0; i < nodos.length; i++) {
      const element = nodos[i];
      while (nodosXID.includes(id)) {
        id += 1;
      }
      nodosXID.push(id);

      let item = { id, titulo: formatearCadena(element.localName) };
      let atributos = [];
      for (let j = 0; j < element.attributes.length; j++) {
        while (nodosXID.includes(id)) {
          id += 1;
        }
        nodosXID.push(id);

        const att = element.attributes[j];
        auxNodo = { id, titulo: formatearCadena(att.localName), atributos: [], children: [], valor: att.nodeValue };
        atributos.push(auxNodo);
      }

      let children = [];
      if (element.children) {
        if (element.children.length > 0) {
          let auxChildren = xmlToObj(element.children, (id + 1));
          children = children.concat(auxChildren);
        }
      }

      item.atributos = atributos;
      item.children = children;
      item.collapse = false;
      items.push(item);
    }

    return items;
  }

  function formatearCadena(valor){
    let cadena = "";

    for (let i = 0; i < valor.length; i++) {
      let character = valor[i];
      if (i===0){
        if (character !== character.toUpperCase()) {
          character = character.toUpperCase();
        }
      }else{
        if (character === character.toUpperCase()) {
          let agregarEspacio = true;
          if ((i + 1) < valor.length){
            if (valor[(i + 1)] === valor[(i + 1)].toUpperCase()) {
              agregarEspacio = false;
            }
          }else{
            agregarEspacio = false;
          }

          if (valor[(i - 1)] !== valor[(i - 1)].toUpperCase()) {
            agregarEspacio = true;
          }

          if (agregarEspacio){
            cadena = cadena + " ";
          }
        }
      }
      cadena = cadena + character;
    }
    return cadena;
  }

  useEffect(() => {
    dataToXML()
  }, []);

  useEffect(() => {
    let arrayNodos = vistaXML();
    setInfoXML(arrayNodos);
  }, [dataXML]);

  function vistaXML(nodos, margen = 0, nivel = 0) {
    if (!nodos) {
      nodos = [...dataXML];
    }
    nivel += 1;
    let items = [];
    for (let i = 0; i < nodos.length; i++) {
      const nodo = nodos[i];
      let children = [];

      for (let j = 0; j < nodo.atributos.length; j++) {
        const att = nodo.atributos[j];

        children.push(
          <div className={"cNodo cNivel" + nivel} key={"nodo" + att.id}>
            <div className="cEtiquetaNodo">
              <div className="cSigno"></div>
              <div className="cTitulo cAtributo">{att.titulo + ": "}</div>
              <div className="cValor">{att.valor}</div>
            </div>
          </div>);
      }

      if (nodo.children.length > 0) {
        let auxChildren = vistaXML(nodo.children, (margen + 5), nivel);
        children = children.concat(auxChildren);
      }

      items.push(
        <div className={"cNodo cNivel" + nivel} id={nodo.id} key={"nodo" + nodo.id}>
          <div className="cEtiquetaNodo">
            <div className={"cSigno" + (nodo.collapse ? " cSignoCollapse" : "")}>
              <div className="cIconoSigno" onClick={() => showHide(nodo.id)}>
                <ArrowRightIcon style={{ fontSize: "inherit" }} />
              </div>
            </div>
            <div className={"cTitulo" + (children.length > 0 ? " cAgrupador" : "") }>{nodo.titulo}</div>
          </div>
          <div className={"cAtributosNodo" + (nodo.collapse ? " cNodoOculto" : " cNodoVisible")} style={{ marginLeft: margen + "px" }}>
            {children}
          </div>
        </div>);
    }
    return items;
  }

  function showHide(id) {
    let nodos = [...dataXML];
    let encontrado = false;
    for (let i = 0; i < nodos.length; i++) {
      let element = nodos[i];
      encontrado = buscaNodo(element, id);
      if (encontrado){
        setDataXML(nodos);
        return;
      }
    }
  }

  function buscaNodo(nodo, id) {
    if (nodo.id === id) {
      nodo.collapse = !nodo.collapse;
      return true;
    }
    
    if (nodo.children.length > 0) {
      let i;
      let result = false;

      for (i = 0; result === false && i < nodo.children.length; i++) {
        result = buscaNodo(nodo.children[i], id);
        if(result){
          return true;
        }
      }

      return result;
    }else{
      return false;
    }
  }

  return (
    <>
      <Modal title={"Documento XML"} onClose={() => ocultar()}>
        <div className={"cContenedorXML"}>
          {infoXML}
        </div>
      </Modal>
    </>);
}

export default VerXML;