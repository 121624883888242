import React, { useRef, useState, useEffect } from 'react';
import { Validaciones } from '@renedelangel/helpers';

import TagFacesIcon from '@material-ui/icons/TagFaces';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setToken } from '../../redux/actions/loginAction';

// Custom hooks
import { useErrorToken } from '../../hooks/errores';

// material-ui icons
import Close from "@material-ui/icons/Close";
import SaveIcon from '@material-ui/icons/Save';

import MenuGeneric from '../../_layout/genericComponents/Menu';
import Preloader from '../../_layout/genericComponents/Preloader';
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';

import FormularioModal from '../../_layout/masterComponents/FormularioModal';

import rutas from './rutas';
import { useTipoUsuario } from '../../hooks/enums';

import { getUsuario } from '../../querys/Usuarios/metodos';
import { updateUsuario } from '../../mutations/Usuarios/metodos';

import { urlServidor } from '../../helpers';
import { rgxPassword } from '../../helpers/regexp';
import { handleMenu, handleFocus, handleModificar, handleGuardar, handleErrorInputText } from '../../_layout/helpers/handles';
import { info, danger } from '../../_layout/helpers/colores';

import Person from '@material-ui/icons/Person';

const { trim } = Validaciones;

function Menu() {

    const dispatch = useDispatch();
    const setTokenRedux = (token) => dispatch(setToken(token));

    const { usuario, token } = useSelector(state => state.login);
    const { usuarioID, tipoID, nombreUsuario, aliasProveedor, logotipoProveedor } = usuario;

    const { tipoAdministrador, tipoUsuario } = useTipoUsuario(tipoID, token);

    const cleanStateUsuario = { usuarioID: null, proveedorID: "", nombreUsuario: "", email: "", password: "", confirmPassword: "", estatusID: "", tipoID: "" };
    const cleanErrorState = {
        proveedorID: { error: false, helperText: ""  },
        nombreUsuario: { error: false, helperText: ""  },
        email: { error: false, helperText: "" },
        password: { error: false, helperText: "" },
        confirmPassword: { error: false, helperText: "" },
        estatusID: { error: false, helperText: ""  },
        tipoID: { error: false, helperText: ""  },
        empresasIDs: { error: false, helperText: ""  }
    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    const [state, setState] = useState(null);
    const [stateUsuario, setStateUsuario] = useState(cleanStateUsuario);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    const [errorToken, setErrorToken] = useState(null);
    const tokenError = useErrorToken(errorToken);

    let nombreUsuarioRef = useRef(null);
    let emailRef = useRef(null);
    let passwordRef = useRef(null);
    let confirmPasswordRef = useRef(null);

    const userMenu = {
        fotoPerfil: <TagFacesIcon />,
        nombre: nombreUsuario
    };

    // const headerSearch = {
    //     placeholder: "Buscar",
    //     onClick: (evt) => console.log(evt)
    // }

    const [openUsuario, setOpenUsuario] = useState(false);

    // const actionsHeader = [{
    //     tipo: "boton",
    //     descripcion: "Dashboard",
    //     icono: Person,
    //     // notificaciones: 0,
    //     onClick: (evt) => console.log("Dashboard onClick", evt)
    // }, {
    //     tipo: "listado",
    //     icono: Dashboard,
    //     descripcion: "Usuario",
    //     notificaciones: 5,
    //     open: openUsuario,
    //     openEvento: () => setOpenUsuario(openUsuario => !openUsuario),
    //     listado: [{
    //         descripcion: "Perfil",
    //         onClick: (evt) => console.log("Perfil", evt)
    //     },{
    //         descripcion: "Configuración",
    //         onClick: (evt) => console.log("Configuración", evt)
    //     },{
    //         division: true
    //     },{
    //         descripcion: "Cerrar sesión",
    //         onClick: handleLogout
    //     }]
    // }];

    const inputs = [{
        disabled,
        id: "nombreUsuario",
        value: stateUsuario.nombreUsuario,
        error: errorState.nombreUsuario.error,
        success: stateUsuario.nombreUsuario && !errorState.nombreUsuario.error ? true : undefined,
        helperText: errorState.nombreUsuario.helperText,
        inputRef: nombreUsuarioRef,
        title: "Nombre del usuario *",
        placeholder: "Capture el nombre del usuario",
        grid: { md: 6, lg: 6 },
        onChange: ({target:{value}}) => handleChange(value, "nombreUsuario"),
        onKeyDown: (evt) => handleFocus(evt, emailRef)
    },{
        disabled: true,
        id: "email",
        value: stateUsuario.email,
        error: errorState.email.error,
        success: stateUsuario.email && !errorState.email.error ? true : undefined,
        helperText: errorState.email.helperText,
        inputRef: emailRef,
        title: "Correo electrónico *",
        placeholder: "Capture el correo electrónico",
        grid: { md: 6, lg: 6 },
        onChange: ({target:{value}}) => handleChange(value, "email"),
        onKeyDown: (evt) => handleFocus(evt, passwordRef)
    },{
        disabled,
        id: "password",
        value: stateUsuario.password,
        error: errorState.password.error,
        success: stateUsuario.password && !errorState.password.error ? true : undefined,
        helperText: errorState.password.helperText,
        inputRef: passwordRef,
        title: `Nueva contraseña *`,
        placeholder: "Capture la contraseña",
        grid: { md: 6, lg: 6 },
        inputProps: { type: "password" },
        onChange: ({target:{value}}) => handleChange(value, "password"),
        onKeyDown: (evt) => handleFocus(evt, confirmPasswordRef)
    },{
        disabled,
        id: "confirmPassword",
        value: stateUsuario.confirmPassword,
        error: errorState.confirmPassword.error,
        success: stateUsuario.confirmPassword && !errorState.confirmPassword.error ? true : undefined,
        helperText: errorState.confirmPassword.helperText,
        inputRef: confirmPasswordRef,
        title: `Confirmar contraseña ${stateUsuario.usuarioID ? "" : "*"}`,
        placeholder: "Capture de nuevo la contraseña",
        grid: { md: 6, lg: 6 },
        inputProps: { type: "password" },
        onChange: ({target:{value}}) => handleChange(value, "confirmPassword"),
        onKeyDown: () => { return; }
    }];

    const accionesFormulario = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: nombreUsuarioRef,
            mensajeCorrecto: `El usuario se actualizó correctamente, los cambios se verán reflejados hasta que vuelva a iniciar sesión`,
            asyncCallback: ftGuardar, setErrorToken
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    const actionsHeader = [{
        tipo: "listado",
        icono: Person,
        descripcion: "Usuario",
        open: openUsuario,
        openEvento: () => setOpenUsuario(openUsuario => !openUsuario),
        listado: [{
            descripcion: "Editar perfil",
            onClick: () => handleModificar({
                parametros: { usuarioID }, token, setState: setStateUsuario, setOpen, setAlert, asyncGet: getUsuario, setErrorToken
            })
        },{ division: true },{
            descripcion: "Cerrar sesión",
            onClick: handleLogout
        }]
    }];

    function handleChange(value, key) { setStateUsuario({ ...stateUsuario, [key]: value }); }

    function handleLogout() {
        setState(null);
        setTimeout(() => setTokenRedux(null), 1000);
    }

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }){
        return handleErrorInputText({ cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState });
    }

    function handleValidaciones({ usuarioID, nombreUsuario, password, confirmPassword }) {

        let error;
        let validaciones = [{
            condicion: !nombreUsuario || trim(nombreUsuario) === "",
            ref: nombreUsuarioRef, keyError: "nombreUsuario",
            mensajeError: "Para que podamos funcionar bien necesitamos que le definas un nombre al usuario"
        }, {
            condicion: (usuarioID && password && !rgxPassword.test(password)) || (!usuarioID && !rgxPassword.test(password)),
            ref: passwordRef, keyError: "password",
            mensajeError: "La estructura de la contraseña debe contener una letra mayúscula, una letra minúscula, un número, un caracter especial y de 8 a 30 caracteres"
        }, {
            condicion: password !== confirmPassword, ref: confirmPasswordRef, keyError: "confirmPassword",
            mensajeError: "La contraseña de confirmación no es igual a la contraseña capturada"
        }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if(error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if(error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { usuarioID, proveedorID, nombreUsuario, email, password, confirmPassword, estatusID, tipoID } = stateUsuario;

            let error = await handleValidaciones({ usuarioID, nombreUsuario, password, confirmPassword });

            if(error) return error;

            await updateUsuario({
                input: { usuarioID, proveedorID, nombreUsuario: trim(nombreUsuario), email, password: !password ? null : password, estatusID, tipoID }
            }, token);

        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setStateUsuario({ ...cleanStateUsuario });
        setErrorState({ ...cleanErrorState });
    }

    function ftEffect() {
        async function effect() {

            let routes = await handleMenu(rutas, async (permisos) => {
                if(!permisos) return true;
                if(tipoAdministrador && permisos.administrador) return true;
                if(tipoUsuario && permisos.usuario) return true;
            });

            setState(routes);

        } effect();
    }

    useEffect(ftEffect, [tipoAdministrador, tipoUsuario]);

    return(!tokenError && !state ? <Preloader /> : <>
        <MenuGeneric
            routes={state}
            logoText={aliasProveedor}
            redirect={{ from: "/", to: "/gen/solicitudes" }}
            userMenu={userMenu}
            logout={handleLogout}
            logoMarca={logotipoProveedor && <img src={`${urlServidor}${logotipoProveedor}?token=${token}`} style={{ height: "50px", width: "auto" }} alt="logotipo" />}
            // headerSearch={headerSearch}
            actionsHeader={actionsHeader}
        />
        <FormularioModal
            open={open}
            title={"Modificar perfil"}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={nombreUsuarioRef}
        />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        /> }
    </>);

}

export default Menu;
