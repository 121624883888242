
export const ADD_USUARIO = `
    mutation addUsuario($input: InputAddUsuario!) {
        addUsuario(input: $input) {
            usuarioID
            fechaAlta
        }
    }
`;

export const UPDATE_USUARIO = `
    mutation updateUsuario($input: InputUpdateUsuario!) {
        updateUsuario(input: $input) {
            usuarioID
            proveedorID
            nombreUsuario
            email
            estatusID
            tipoID
        }
    }
`;

export const DELETE_USUARIO = `
    mutation deleteUsuario($usuarioID: ID!) {
        deleteUsuario(id: $usuarioID)
    }
`;

export const ASIGNAR_EMPRESAS_USUARIO = `
    mutation asignarEmpresasUsuario($usuarioID: ID!, $empresasIDs: [String]!) {
        asignarEmpresasUsuario(id: $usuarioID, empresasIDs: $empresasIDs)
    }
`;
