import React, { useState, useEffect } from 'react'

// Redux
import { useSelector } from "react-redux";

// Custom hooks
import { useErrorToken } from '../../hooks/errores';

// master components
import Tabla from '../../_layout/masterComponents/Tabla';

// generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import { NetworkError } from "../../_layout/genericComponents/Metodos";

// material-ui icons
import SyncIcon from '@material-ui/icons/Sync';
import TopicIcon from '@mui/icons-material/Topic';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import { handleAlertGeneric, handleAlertMutationGeneric, Espera} from '../../_layout/helpers/handles';

import { tablaDocumentos, seleccionTiposDocumento, getDocumento } from '../../querys/Documentos/metodos';
import { documentosEstado } from '../../mutations/Documentos';
import { seleccionEmpresa, } from '../../querys/Empresas/metodos'
import { validacionEstado } from '../../rest/descargamasiva';

import { info, danger, primary, success } from '../../_layout/helpers/colores';
import CodeIcon from '@mui/icons-material/Code';
import VerXML from '../VerXML';

function Documentos() {

    const { token } = useSelector(state => state.login);
    const [openXML, setOpenXML] = useState(false);
    const [xml, setXML] = useState("");

    const estadosSAT = [
        { value: "VIGENTE", label: "VIGENTE" },
        { value: "CANCELADO", label: "CANCELADO" },
        { value: "NO ENCONTRADO", label: "NO ENCONTRADO" }
    ];
    const tipoComprobante = [
        { value: "I", label: "INGRESO" },
        { value: "E", label: "EGRESO" },
        { value: "P", label: "PAGO" },
        { value: "N", label: "NÓMINA" },
        { value: "T", label: "TRASLADO" },
    ]
    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});
    const [alert, setAlert] = useState(null);
    const [actualizar, setActualizar] = useState(false);
    const [errorToken, setErrorToken] = useState(null);
    const tokenError = useErrorToken(errorToken);

    const acciones = [{
        icono: CodeIcon,
        color: "success",
        id: "verXml",
        descripcion: "Ver xml",
        parametros: [{ campo: "xmlCFDI" }, { campo: "xmlCFDI" }],
        disabled: { multiseleccion: true },
        onClick: ({ xmlCFDI }) => verXML(xmlCFDI)
    }];
    const botones = [{
        icono: AssignmentTurnedInIcon,
        color: success,
        descripcion: "Validacion de Estatus",
        onClick: (e) => {
            let ArrayDoctoID = []; 
            e.forEach(({doctoID}) =>{
            ArrayDoctoID.push(doctoID)
        });
        handleAlertGeneric({
            setAlert,
            mensaje: {
                title: 'Validacion',
                descripcion: `¿Desea validar el estatus en el SAT del XML seleccionado?`,
                msjConfirmacion: 'Si',
                msjCancelacion: 'No'
            },
            onCancel: () => setAlert(null),
            onConfirm: () => validacionEstatus(ArrayDoctoID)
        }) 
    }
    },{
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "doctoID",
        color: primary,
        title: "Documentos",
        iconTable: <TopicIcon />,
        headers: [
            { variable: "doctoID", descripcion: "ID" },
            { variable: "tipoDoctoID", descripcion: "tipoDoctoID", hide: true },
            { variable: "nombreEmisor", descripcion: "Emisor" },
            { variable: "nombreReceptor", descripcion: "Receptor" },
            { variable: "serie", descripcion: "Serie" },
            { variable: "folio", descripcion: "Folio" },
            { variable: "fecha", descripcion: "Fecha" },
            { variable: "uuid", descripcion: "UUID" },
            { variable: "total", descripcion: "Total" },
            { variable: "estadoSAT", descripcion: "Estado" },
            { variable: "empresaID", descripcion: "empresa", hide: true },
            { variable: "xmlCFDI", descripcion: "xmlCFDI", hide: true }
        ],
        responsiveTitle: ["serie", "folio", "fecha"],
        filter: [
            { campo: "nombreEmisor", placeholder: "Emisor" },
            { campo: "nombreReceptor", placeholder: "Receptor" },
            { campo: "serie", placeholder: "Serie" },
            { campo: "folio", placeholder: "Folio" },
            { campo: "uuid", placeholder: "UUID" }
        ],
        alineacion: [{ columnas: [0, 3, 4], alineacion: "center" }],
        formato: [{ columnas: ["total"], tipo: "moneda" },
        { columnas: ["fecha"], tipo: "fecha" }
        ],
        selectFilter: [
            {
                campo: "empresaID", placeholder: "Empresa a filtrar",
                retorna: "integer", limpiarFiltro: "Mostrar todas las empresas",
                tipo: "autocomplete",
                data: seleccionables.empresas ? seleccionables.empresas : [], grid: { sm: 4, md: 4, lg: 6 }
            },
            {
                campo: "estadoSAT", placeholder: "Estado SAT filtrar",
                retorna: "string", limpiarFiltro: "Mostrar todos los estados SAT",
                tipo: "autocomplete",

                data: estadosSAT, grid: { sm: 4, md: 4, lg: 4 }
            },
            {
                campo: "tipoDoctoID", placeholder: "Tipo de comprobante a filtrar",
                retorna: "integer", limpiarFiltro: "Mostrar todos las tipos de documentos",
                tipo: "autocomplete",
                data: seleccionables.tiposDocumento ? seleccionables.tiposDocumento : [], grid: { sm: 4, md: 4, lg: 4 }
            },
            {
                campo: "tipoComprobante", placeholder: "Tipo comprobante a filtrar",
                retorna: "string", limpiarFiltro: "Mostrar todos los tipos comprobante",
                tipo: "autocomplete",
                data: tipoComprobante, grid: { sm: 4, md: 4, lg: 4 }
            },
            {
                campo: "rfcEmisor", placeholder: "RFC emisor",
                retorna: "string", limpiarFiltro: "RFC emisor",
                defaultValue: null,
                tipo: "text",
                texto: "rfcEmisor",
                data: [],
                grid: { sm: 4, md: 4, lg: 6 }
            },
            {
                campo: "rfcReceptor", placeholder: "RFC receptor",
                retorna: "string", limpiarFiltro: "RFC receptor",
                defaultValue: null,
                tipo: "text",
                texto: "rfcReceptor",
                data: [],
                grid: { sm: 4, md: 4, lg: 6 }
            },
        ],
        rangoFechas: { ini: "fechaIni", fin: "fechaFin" },
        multiseleccion:[{ campo: 'doctoID'}]
    }

    async function verXML(xmlCFDI) {
        setXML(xmlCFDI);
        setOpenXML(true);
    }

     function validacionEstatus(ArrayDoctoID) {

         async function validacionEstatus(){
         try {

        if (ArrayDoctoID.length === 0)  throw new Error(); 
          
        let infoDocumento = []; 
        for (let i = 0; i < ArrayDoctoID.length; i++ ){
            let informacion = await getDocumento({ filtro: {doctoID: ArrayDoctoID[i]} }, token);
            infoDocumento.push(informacion)
        }

        for (let a = 0; a < infoDocumento.length; a++ ){     
            // recorrido de arreglo para mandar informacion a funcion  
            console.log(infoDocumento)
            await consultaEstadoSAT( infoDocumento[a].rfcEmisor, infoDocumento[a].rfcReceptor, infoDocumento[a].total, infoDocumento[a].uuid, infoDocumento[a].doctoID);
        }

        handleAlertMutationGeneric({
            setAlert,
            setActualizar,
            actualizar,
            mensajes: {
                msjCorrecto: {
                    title: "Se generó con éxito",
                    descripcion: `El estado se actualizó.`,
                    msjConfirmacion: "Aceptar"
                }
            }
        })

    } catch({}) {       
            setAlert({
                descripcion: "No hay registros seleccionados.",
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
         }
         return validacionEstatus();
    }

    async  function consultaEstadoSAT( cRFCEmisior, cRFCReceptor,nTotal,cUUID,nDoctoID)
        {

            let xml = `<?xml version="1.0"?>
            <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tem="http://tempuri.org/">
            <soapenv:Header/>
                <soapenv:Body>
                    <tem:Consulta>
                        <tem:expresionImpresa>
                        <![CDATA[?re=${cRFCEmisior}&rr=${cRFCReceptor}&tt=${nTotal}&id=${cUUID}]]>
                        </tem:expresionImpresa>
                    </tem:Consulta>
                </soapenv:Body>
            </soapenv:Envelope>`;

            console.log(xml)

            // se manda llamar el post 

            Espera(
                setAlert,
                    {
                    title: "Espere...",
                    descripcion: `Generando consulta de estado.`
                });

           xml = await validacionEstado({ xml, token });
           let CodigoEstatus = xml.result.Envelope.Body[0].ConsultaResponse[0].ConsultaResult[0].CodigoEstatus[0];
           let Estado = xml.result.Envelope.Body[0].ConsultaResponse[0].ConsultaResult[0].Estado[0];
           let EsCancelable = xml.result.Envelope.Body[0].ConsultaResponse[0].ConsultaResult[0].EsCancelable[0];

           await documentosEstado({input: { doctoID: nDoctoID, estadoSAT: Estado}}, token );
           
    }

    function ftEffect() {
        async function effect() {
            try {
                let empresas = await seleccionEmpresa({ filtro: {} }, token);
                let tiposDocumento = await seleccionTiposDocumento({ filtro: {} }, token);

                setSeleccionables({ empresas, tiposDocumento });
                setAlert(null)
            }
            catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
                setErrorToken(message);
            }
        } effect();
    };
    useEffect(ftEffect, []);

    return (!tokenError && <>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaDocumentos}
            token={token}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
        {openXML && <VerXML ocultar={() => setOpenXML(false)} xml ={xml} />}
    </>);

}

export default Documentos;
