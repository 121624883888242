import React, { useState, useRef } from 'react'
import { Validaciones } from '@renedelangel/helpers';

// Redux
import { useSelector } from "react-redux";

// Custom hooks
import { useErrorToken } from '../../hooks/errores';

// master components
import Tabla from '../../_layout/masterComponents/Tabla';
import FormularioModal from '../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import MailIcon from '@material-ui/icons/Mail';

import { tablaProveedores, getProveedores, sendEmailProveedor } from '../../querys/Proveedores/metodos';
import { deleteProveedores } from '../../mutations/Proveedores/metodos';
import { addProveedor, updateProveedor } from '../../rest/proveedores';

import { urlServidor } from '../../helpers';
import { rgxCorreo, rgxUrl } from '../../helpers/regexp';
import { handleFocus, handleErrorGeneric, handleErrorInputText, handelAlertEliminar, handleModificar, handleEliminar, handleGuardar } from '../../_layout/helpers/handles';
import { titleEspera, genericDescripcionEspera, errorTitle, errorBoton, alertEliminarTitle, alertEliminarMsjCancelacion } from '../../_layout/helpers/handlesText';
import { info, danger, success, primary, warning } from '../../_layout/helpers/colores';

const { trim } = Validaciones;

function Proveedores() {

    const { token } = useSelector(state => state.login);

    const cleanState = { proveedorID: null, nombre: "", alias: "", logotipo: null, url: "", emailID: null, emailService: "", host: "", port: "", user: "", pass: "", from: "" };
    const cleanErrorState = { nombre: { error: false, helperText: ""  },
        alias: { error: false, helperText: "" },
        url: { error: false, helperText: "" },
        emailService: { error: false, helperText: "" },
        host: { error: false, helperText: "" },
        port: { error: false, helperText: "" },
        user: { error: false, helperText: "" },
        pass: { error: false, helperText: "" },
        from: { error: false, helperText: "" },
    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    const [errorToken, setErrorToken] = useState(null);
    const tokenError = useErrorToken(errorToken);

    let titleAccion = state.proveedorID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} proveedor`;

    let nombreRef = useRef(null);
    let aliasRef = useRef(null);
    let urlRef = useRef(null);
    let emailServiceRef = useRef(null);
    let hostRef = useRef(null);
    let portRef = useRef(null);
    let userRef = useRef(null);
    let passRef = useRef(null);
    let fromRef = useRef(null);

    const acciones = [{
        icono: MailIcon,
        color: primary,
        descripcion: "Verificar configuración de correo",
        parametros: [{ campo: "proveedorID" }, { campo: "nombre" }],
        disabled: { multiseleccion: true },
        onClick: parametros => {
            setAlert({
                title: alertEliminarTitle,
                descripcion: "Estamos a punto de realizar una prueba de envío de correo con la configuración actual del proveedor",
                tipo: warning,
                msjConfirmacion: "¡Adelante!",
                msjCancelacion: alertEliminarMsjCancelacion,
                onConfirm: () => ftVerificarEmail(parametros),
                onCancel: () => setAlert(null),
            });
        }
    }, {
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "proveedorID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getProveedores, setErrorToken
        }, ({logotipo}) => setState(state => ({ ...state, urlLogotipo: `${urlServidor}${logotipo}?token=${token}` })))
    }, {
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "proveedorID" }, { campo: "nombre" }],
        disabled: { multiseleccion: true },
        onClick: ({ proveedorID, nombre = "seleccionado" }) => handelAlertEliminar({
            setAlert,
            mensaje: `al proveedor ${nombre}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { proveedorID }, asyncDelete: deleteProveedores, setErrorToken
            })
        })
    }];

    const botones = [{
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "proveedorID",
        color: primary,
        title: "Proveedores",
        iconTable: <BusinessCenterIcon />,
        headers: [
            { variable: "proveedorID", descripcion: "ID" },
            { variable: "nombre", descripcion: "Nombre" },
            { variable: "alias", descripcion: "Alias" },
            { variable: "logotipo", descripcion: "Logotipo" }
        ],
        responsiveTitle: ["proveedorID", "nombre"],
        filter: [{ campo: "nombre" }],
        alineacion: [{ columnas: [0,3], alineacion: "center" }],
        formato: [{ columnas: ["logotipo"], tipo: "imagen" }],
        // multiseleccion: [{ campo: "nombre" }],
        // rangoFechas: { ini: "fechaIni", fin: "fechaFin" },
    }

    const inputs = [{
        disabled,
        id: "nombre",
        value: state.nombre,
        error: errorState.nombre.error,
        success: state.nombre && !errorState.nombre.error ? true : undefined,
        helperText: errorState.nombre.helperText,
        inputRef: nombreRef,
        title: "Nombre del proveedor *",
        placeholder: "Capture el nombre del proveedor",
        grid: { md: 6, lg: 6 },
        onChange: ({target:{value}}) => handleChange(value, "nombre"),
        onKeyDown: (evt) => handleFocus(evt, aliasRef)
    },{
        disabled: state.proveedorID ? true : disabled,
        id: "alias",
        value: state.alias,
        error: errorState.alias.error,
        success: state.alias && !errorState.alias.error ? true : undefined,
        helperText: errorState.alias.helperText,
        inputRef: aliasRef,
        title: "Alias del proveedor *",
        placeholder: "Capture el alias del proveedor",
        grid: { md: 6, lg: 6 },
        onChange: ({target:{value}}) => handleChange(value, "alias"),
        onKeyDown: (evt) => handleFocus(evt, emailServiceRef)
    },{
        disabled,
        id: "logotipo",
        value: state.urlLogotipo,
        title: "Imagen del logotipo *",
        tipo: "imageupload",
        onChange: handleSetImagen,
        removeImage: state.logotipo
    },{
        disabled,
        id: "url",
        value: state.url,
        error: errorState.url.error,
        success: state.url && !errorState.url.error ? true : undefined,
        helperText: errorState.url.helperText,
        inputRef: urlRef,
        title: "Sitio web",
        placeholder: "Capture la url donde se aloja la página del proveedor",
        onChange: ({target:{value}}) => handleChange(value, "url"),
        onKeyDown: (evt) => handleFocus(evt, emailServiceRef)
    },{
        disabled,
        id: "emailService",
        value: state.emailService,
        error: errorState.emailService.error,
        success: state.emailService && !errorState.emailService.error ? true : undefined,
        helperText: errorState.emailService.helperText,
        inputRef: emailServiceRef,
        title: "Proveedor de email *",
        placeholder: "Capture el proveedor del email",
        grid: { md: 6, lg: 4 },
        onChange: ({target:{value}}) => handleChange(value, "emailService"),
        onKeyDown: (evt) => handleFocus(evt, hostRef)
    },{
        disabled,
        id: "host",
        value: state.host,
        error: errorState.host.error,
        success: state.host && !errorState.host.error ? true : undefined,
        helperText: errorState.host.helperText,
        inputRef: hostRef,
        title: "Host de email *",
        placeholder: "Capture el host del email",
        grid: { md: 6, lg: 4 },
        onChange: ({target:{value}}) => handleChange(value, "host"),
        onKeyDown: (evt) => handleFocus(evt, portRef)
    },{
        disabled,
        id: "port",
        value: state.port,
        error: errorState.port.error,
        success: state.port && !errorState.port.error ? true : undefined,
        helperText: errorState.port.helperText,
        inputRef: portRef,
        title: "Puerto del email *",
        placeholder: "Capture el puerto el email",
        grid: { md: 6, lg: 4 },
        onChange: ({target:{value}}) => handleChange(value, "port"),
        onKeyDown: (evt) => handleFocus(evt, userRef)
    },{
        disabled,
        id: "user",
        value: state.user,
        error: errorState.user.error,
        success: state.user && !errorState.user.error ? true : undefined,
        helperText: errorState.user.helperText,
        inputRef: userRef,
        title: "Usuario del email *",
        placeholder: "Capture el usuario del email",
        grid: { md: 6, lg: 4 },
        onChange: ({target:{value}}) => handleChange(value, "user"),
        onKeyDown: (evt) => handleFocus(evt, passRef)
    },{
        disabled,
        id: "pass",
        value: state.pass ? state.pass : "",
        error: errorState.pass.error,
        success: state.pass && !errorState.pass.error ? true : undefined,
        helperText: errorState.pass.helperText,
        inputRef: passRef,
        title: "Contraseña del email *",
        placeholder: "Capture el contraseña del email",
        grid: { md: 6, lg: 4 },
        inputProps: { type: "password" },
        onChange: ({target:{value}}) => handleChange(value, "pass"),
        onKeyDown: (evt) => handleFocus(evt, fromRef)
    },{
        disabled,
        id: "from",
        value: state.from,
        error: errorState.from.error,
        success: state.from && !errorState.from.error ? true : undefined,
        helperText: errorState.from.helperText,
        inputRef: fromRef,
        title: "Alias del correo *",
        placeholder: "Capture el alias para el envío del correo",
        grid: { md: 6, lg: 4 },
        onChange: ({target:{value}}) => handleChange(value, "from"),
        onKeyDown: () => { return; }
    }];

    const accionesFormulario = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: nombreRef,
            mensajeCorrecto: `El proveedor se ${state.proveedorID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
            setErrorToken
        })
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }){
        return handleErrorInputText({ cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState });
    }

    function handleValidaciones({ proveedorID, nombre, alias, logotipo, url, emailID, emailService, host, port, user, pass, from }) {

        let error;
        let validaciones = [{
            condicion: !nombre || trim(nombre) === "", ref: nombreRef, keyError: "nombre",
            mensajeError: "Para que podamos funcionar bien necesitamos que le definas un nombre al proveedor"
        },{
            condicion: !/^[aA-zZ]+$/.test(alias), ref: aliasRef, keyError: "alias",
            mensajeError: "Para que podamos funcionar bien necesitamos que el alias solo se conforme de letras y ningún caracter especial, número o espacios en blanco"
        },{
            condicion: !rgxUrl.test(url), ref: urlRef, keyError: "url",
            mensajeError: "Para que podamos funcionar bien necesitamos que se captura una dirección del sitio web correcta"
        },{
            condicion: !emailService || trim(emailService) === "", ref: emailServiceRef, keyError: "emailService",
            mensajeError: "Para que podamos funcionar bien necesitamos que se capture el nombre del proveedor del servicio de la cuenta del correo electrónico"
        },{
            condicion: !host || trim(host) === "", ref: hostRef, keyError: "host",
            mensajeError: "Para que podamos funcionar bien necesitamos que se defina el servidor de la cuenta del correo electrónico"
        },{
            condicion: !port || trim(port) === "", ref: portRef, keyError: "port",
            mensajeError: "Para que podamos funcionar bien necesitamos que se capture el puerto del servidor de la cuenta del correo electrónico"
        },{
            condicion: !rgxCorreo.test(user), ref: userRef, keyError: "user",
            mensajeError: "Para que podamos funcionar bien necesitamos que se defina el usuario de la cuenta del correo electrónico"
        },{
            condicion: (!proveedorID || (proveedorID && !emailID)) && (!pass || trim(pass) === ""), ref: passRef, keyError: "pass",
            mensajeError: "Para que podamos funcionar bien necesitamos que se capture la contraseña de la cuenta del correo elctrónico"
        },{
            condicion: !rgxCorreo.test(from), ref: fromRef, keyError: "from",
            mensajeError: "Para que podamos funcionar bien necesitamos que se defina un alias para el envío del correo electrónico"
        }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if(error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if(error) return error;

        handleErrorGeneric({
            condicion: !proveedorID && !logotipo,
            mensaje: "Es necesario subir una imagen"
        });

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { proveedorID, nombre, alias, logotipo, url, emailID, emailService, host, port, user, pass, from } = state;

            let error = await handleValidaciones({ proveedorID, nombre, alias, logotipo, url, emailID,
                emailService, host, port: port ? port.toString() : "", user, pass, from });

            if(error) return error;

            let imagen = proveedorID && (!logotipo || typeof logotipo === "string") ? {} : { logotipo };
            let password = proveedorID && (!pass || trim(pass) === "") ? {} : { pass };

            if(proveedorID)await updateProveedor({
                data: { ...imagen, ...password, alias, nombre: trim(nombre), proveedorID, url, emailService, host, port, user, from }, token
            });
            else await addProveedor({ data: { nombre, alias, logotipo, url, emailService, host, port, user, pass, from }, token });

        }
        return ftGuardar();
    }

    function ftVerificarEmail(parametros) {
        async function ftEmail() {

            try {

                setAlert({
                    title: titleEspera,
                    descripcion: genericDescripcionEspera("generación del envío del correo"),
                    onConfirm: () => setAlert(null),
                    tipo: success,
                    showConfirm: false,
                    showCancel: false
                });

                let descripcion = await sendEmailProveedor(parametros, token);

                setActualizar(!actualizar);

                setAlert({
                    descripcion,
                    title: "¡El correo va en camino!",
                    tipo: success,
                    msjConfirmacion: "Gracias",
                    onConfirm: () => setAlert(null)
                });

            } catch({message:descripcion}) {

                setErrorToken(descripcion);

                setActualizar(!actualizar);
                setAlert({
                    descripcion,
                    title: errorTitle,
                    tipo: danger,
                    msjConfirmacion: errorBoton,
                    onConfirm: () => setAlert(null)
                });

            }

        } ftEmail();
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function handleSetImagen(logotipo) { setState({ ...state, logotipo, urlLogotipo: null }); }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleCloseNotificacion() { setNotificaciones({ ...cleanNotificaciones }); }

    return(!tokenError && <>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaProveedores}
            token={token}
            url={urlServidor}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={handleCloseNotificacion}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={nombreRef}
        />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        /> }
    </>);
}

export default Proveedores;
