import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';
import { DELETE_SOLICITUD } from '../index';

export const deleteSolicitud = async (variables, token) => {
    try {

        let { deleteSolicitud } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: DELETE_SOLICITUD
        });

        return deleteSolicitud;

    } catch({message}) { throw new Error(message); }
};
