import React, { useState, useRef, useEffect } from 'react'
import { Validaciones } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';

// Custom hooks
import { useErrorToken } from '../../hooks/errores';

// master components
import Tabla from '../../_layout/masterComponents/Tabla';
import FormularioModal from '../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import Loader from '../../_layout/genericComponents/Loader';
import { NetworkError } from "../../_layout/genericComponents/Metodos";

// material-ui icons
import Close from '@material-ui/icons/Close';
import SyncIcon from '@material-ui/icons/Sync';
import SendIcon from '@material-ui/icons/Send';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import NextWeekIcon from '@material-ui/icons/NextWeek';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { getSolicitud, seleccionEstadoSolicitud, tablaSolicitudes } from '../../querys/Solicitudes/metodos';
import { deleteSolicitud } from '../../mutations/Solicitudes/metodos';
import { selectEmpresasUsuario, selectEmpresaIDUsuario } from '../../querys/Usuarios/metodos';
import { seleccionProveedores } from '../../querys/Proveedores/metodos';
import { seleccionEmpresa } from '../../querys/Empresas/metodos';
import { selectEnumEstatus, selectEnumTipoUsuarios } from '../../querys/Enums/metodos'
import { descargaMasivaSAT, dowloadZIP, bulkXML } from '../../rest/descargamasiva';

import { rgxNumeros, rgxRFC } from '../../helpers/regexp';
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar } from '../../_layout/helpers/handles';
import { info, danger, success, primary, white } from '../../_layout/helpers/colores';
import { handleAlertGeneric } from '../../_layout/helpers/handles';
import { handleAlertMutationGeneric } from '../../_layout/helpers/handles';

import { EnumEstatus } from '../../enums';

import moment from 'moment';
import ArchiveIcon from '@material-ui/icons/Archive';

const { trim } = Validaciones;

function Solicitudes() {

    const { token, usuario } = useSelector(state => state.login);
    const { usuarioID } = usuario;

    const cleanState = { solicitudID: null, cer: "", key: "", password: "", empresaID: "", fechaIni: "", fechaFin: "", receptor: "", emisor: "", tipoSolicitud: "CFDI", tipo: "cfdi",complemento:null,tipoComprobante:null,estadoComprobante:null,folioFiscal:null,rfcTercero:null };
    const cleanErrorState = {
        empresaID: { error: false, helperText: ""  },
        cer: { error: false, helperText: ""  },
        key: { error: false, helperText: ""  },
        password: { error: false, helperText: ""  },
        fechaIni: { error: false, helperText: ""  },
        fechaFin: { error: false, helperText: "" },
        receptor: { error: false, helperText: "" },
        emisor: { error: false, helperText: "" },
        tipoSolicitud: { error: false, helperText: ""  },
        tipo: { error: false, helperText: ""  },
        complemento: { error: false, helperText: ""  },
        tipoComprobante: { error: false, helperText: ""  },
        estadoComprobante: { error: false, helperText: ""  },
        folioFiscal: { error: false, helperText: ""  },
        rfcTercero: { error: false, helperText: ""  },
    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const cleanDisabledInputs = { empresaID: false, cer: false, key: false, emisor: false, receptor: false, fechaIni: false, fechaFin: false, tipo: false, tipoSolicitud: false };

    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});

    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [disabledInputs, setDisabledInputs] = useState(cleanDisabledInputs);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    const [errorToken, setErrorToken] = useState(null);
    const tokenError = useErrorToken(errorToken);

    let passwordRef = useRef(null);
    let receptorRef = useRef(null);
    let emisorRef = useRef(null);
    let rfcTerceroRef = useRef(null);
    let folioFiscalRef = useRef(null);
    
    

    const acciones = [{
        icono: SendIcon,
        color: info,
        id: "verifica",
        descripcion: "Verificar estado",
        parametros: [{ campo: "solicitudID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert, asyncGet: getSolicitud, formatearResultado: [
                { id: "fechaIni", formato: (data) => moment(data) },
                { id: "fechaFin", formato: (data) => moment(data) },
                { id: "emisor", formato: (data) => data ? data : ""},
                { id: "receptor", formato: (data) => data ? data : ""}
            ], setErrorToken
        }, ({ guardarFIEL }) => {
            let keys = Object.keys(disabledInputs);
            let newObj = {};
            keys.forEach(key => { newObj = { ...newObj, [key]: true }; });
            setDisabledInputs({
                ...newObj,
                cer: !!guardarFIEL,
                key: !!guardarFIEL
            });
        })
    },{
        icono: CloudDownloadIcon,
        color: success,
        id: "download",
        descripcion: "Descargar documentos",
        parametros: [{ campo: "solicitudID" }, { campo: "IdSolicitud"}],
        disabled: { multiseleccion: true },
        onClick: descargarDocumentos
    },{
        icono: Close,
        color: danger,
        id: "delete",
        descripcion: "Eliminar",
        parametros: [{ campo: "solicitudID" }, { campo: "IdSolicitud"}],
        disabled: { multiseleccion: true },
        onClick: ({ solicitudID, IdSolicitud = "seleccionada" }) => handelAlertEliminar({
            setAlert,
            mensaje: `la solicitud ${IdSolicitud}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { solicitudID }, asyncDelete: deleteSolicitud, setErrorToken
            })
        })
    },
{
    icono: ArchiveIcon,
    color:  white,
    id: "capturaXML",
    descripcion: "Capturar XML",
    parametros: [{campo: 'solicitudID'}, {campo: 'empresaID'}],
    disabled: {multiseleccion: true},
    onClick: (parametros) =>{
        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Captura masiva XML",
                descripcion: "¿Desea guardar masivamente los XML?",
                tipo: "warning",
                msjConfirmacion: "Aceptar",
                msjCancelacion: "Cancelar"
            },
            onConfirm: () => handleAlertMutationGeneric({
                token,
                setAlert,
                setActualizar,
                actualizar,
                parametros: {},
                asyncMutation: () => bulk(parametros, token),
                mensajes: {
                    msjEspera: {
                        title: "Espere...",
                        descripcion: "Se estan guardando los datos",
                        tipo: success
                    },
                    msjCorrecto: {
                        title: "¡ÉXITO!",
                        descripcion: '¡Se guardaron de manera correcta sus datos!',
                        msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
        
    }
}];


    let bulk = async (parametros, token) =>{
    await bulkXML(parametros, token)}
    

    const botones = [{
        icono: SendIcon,
        color: success,
        descripcion: "Generar solicitud",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "solicitudID",
        color: primary,
        title: "Solicitudes",
        iconTable: <MarkunreadMailboxIcon />,
        headers: [
            { variable: "solicitudID", descripcion: "ID" },
            {variable: "empresaID", descripcion: "empresaID", hide: true},
            { variable: "razonSocial", descripcion: "Solicitante" },
            { variable: "fechaIni", descripcion: "Inicio" },
            { variable: "fechaFin", descripcion: "Final" },
            { variable: "emisor", descripcion: "Emisor" },
            { variable: "receptor", descripcion: "Receptor" },
            { variable: "tipoSolicitud", descripcion: "Solicitud" },
            { variable: "tipo", descripcion: "Tipo" },
            { variable: "IdSolicitud", descripcion: "ID solicitud SAT" },
            { variable: "estadoSolicitud", hide: true },
            { variable: "mensajeEstadoSolicitud", descripcion: "Estado solicitud" },
            { variable: "zip", descripcion: "Descarga" },
            { variable: "fechaAlta", descripcion: "Fecha alta" },
            { variable: "tipoComprobante", descripcion: "Tipo Comprobante" },
            { variable: "estadoComprobante", descripcion: "Estado Comprobante" },
            { variable: "complemento", descripcion: "Complemento" },
            { variable: "folioFiscal", descripcion: "Folio Fiscal" },
        ],
        responsiveTitle: ["solicitudID", "IdSolicitud", "razonSocial"],
        filter: [
            // { campo: "razonSocial", placeholder: "Solicitante" },
            { campo: "emisor", placeholder: "Emisor" },
            { campo: "receptor", placeholder: "Receptor" },
            { campo: "tipoSolicitud", placeholder: "Tipo de solicitud" },
            { campo: "tipo", placeholder: "Tipo" },
            { campo: "tipoComprobante", placeholder: "Tipo comprobante"},
            { campo: "estadoComprobante", placeholder: "Estado Comprobante" },
            { campo: "complemento", placeholder: "Complemento" },
        ],
        selectFilter: [
            {
                campo: "razonSocial", placeholder: "Seleccionar empresa",
                retorna: "string", limpiarFiltro: "Mostrar todas las empresas solicitantes",
                // data: seleccionables.empresas ? seleccionables.empresas : []
                data: seleccionables.empresaIDUsuario ? seleccionables.empresaIDUsuario : []
            },
            {
                campo: "estadoSolicitud", placeholder: "Seleccionar el estado de solicitud",
                retorna: "number", limpiarFiltro: "Mostrar todos lo estados de solicitud",
                data: seleccionables.estadoSolicitud ? seleccionables.estadoSolicitud : []
            }
        ],
        alineacion: [{ columnas: [0,2,3,6,7,9,10,11,12,13], alineacion: "center" }],
        accionesEffect: [
            { botones: ["verifica"], ocultar: (data) => data["zip"].value },
            { botones: ["verifica"], ocultar: (data) => Number(data["estadoSolicitud"].value) > 3 },
            { botones: ["download"], ocultar: (data) => !data["zip"].value },
            {botones: ["capturaXML"], ocultar: (data) => !data["zip"].value}
        ],
        formato: [
            { columnas: ["fechaIni", "fechaFin"], tipo: "fecha", onlyDate: true },
            { columnas: ["tipoSolicitud", "tipo"], tipo: "custom", callback: (value) => typeof value === "string" ? value.toUpperCase() : value },
            { columnas: ["fechaAlta"], tipo: "fecha" },
            { columnas: ["zip"], tipo: "estatus" }
        ],
        rangoFechas: { ini: "fechaIni", fin: "fechaFin" },
        // multiseleccion: [{ campo: "rfcSolicitante" }]
    }

    const inputs = [{
        disabled: disabled ? disabled : disabledInputs.empresaID,
        id: "empresaID",
        value: state.empresaID,
        error: errorState.empresaID.error,
        success: state.empresaID && !errorState.empresaID.error ? true : undefined,
        helperText: errorState.empresaID.helperText,
        title: "Empresa solicitante *",
        placeholder: "Seleccionar al solicitante",
        tipo: "autocomplete",
        data: seleccionables.empresasUsuario,
        onChange: ({value}) => setState({ ...state, empresaID: value})
    },{
        id: "labelCer",
        tipo: "label",
        title: "Certificado (.cer)",
        grid: { xs: 3, sm: 3, md: 3, lg: 2 }
    },{
        disabled: disabled ? disabled : disabledInputs.cer,
        id: "cer",
        value: state.cer,
        error: errorState.cer.error,
        success: state.cer && !errorState.cer.error ? true : undefined,
        helperText: errorState.cer.helperText,
        placeholder: "Ubcación del certificado",
        inputProps: { type: "file", accept: ".cer" },
        grid: { xs: 9, sm: 9, md: 9, lg: 4 },
        onChange: ({target:{value, files}}) => handleChangeFiles({value, files}, "cer"),
        onKeyDown: () => { return; }
    },{
        id: "labelKey",
        tipo: "label",
        title: "Clave privada (.key)",
        grid: { xs: 3, sm: 3, md: 3, lg: 2 }
    },{
        disabled: disabled ? disabled : disabledInputs.key,
        id: "key",
        value: state.key,
        error: errorState.key.error,
        success: state.key && !errorState.key.error ? true : undefined,
        helperText: errorState.key.helperText,
        placeholder: "Ubcación de la llave privada",
        inputProps: { type: "file", accept: ".key" },
        grid: { xs: 9, sm: 9, md: 9, lg: 4 },
        onChange: ({target:{value, files}}) => handleChangeFiles({value, files}, "key"),
        onKeyDown: () => { return; }
    },{
        disabled,
        id: "password",
        value: state.password,
        error: errorState.password.error,
        success: state.password && !errorState.password.error ? true : undefined,
        helperText: errorState.password.helperText,
        inputRef: passwordRef,
        title: "Contraseña de clave privada *",
        placeholder: "Capture la contraseña",
        grid: { md: 4, lg: 4 },
        inputProps: { type: "password" },
        onChange: ({target:{value}}) => handleChange(value, "password"),
        onKeyDown: (evt) => handleFocus(evt, emisorRef)
    },{
        disabled: disabled ? disabled : disabledInputs.emisor,
        id: "emisor",
        value: state.emisor,
        error: errorState.emisor.error,
        success: state.emisor && !errorState.emisor.error ? true : undefined,
        helperText: errorState.emisor.helperText,
        inputRef: emisorRef,
        title: "RFC emisor",
        placeholder: "Capture el RFC emisor",
        grid: { md: 4, lg: 4 },
        onChange: ({target:{value}}) => handleChange(value.toUpperCase(), "emisor"),
        onKeyDown: (evt) => handleFocus(evt, receptorRef)
    },{
        disabled: disabled ? disabled : disabledInputs.receptor,
        id: "receptor",
        value: state.receptor,
        error: errorState.receptor.error,
        success: state.receptor && !errorState.receptor.error ? true : undefined,
        helperText: errorState.receptor.helperText,
        inputRef: receptorRef,
        title: "RFC receptor",
        placeholder: "Capture el RFC receptor",
        grid: { md: 4, lg: 4 },
        onChange: ({target:{value}}) => handleChange(value.toUpperCase(), "receptor"),
        onKeyDown: () => { return; }
    },{
        disabled: disabled ? disabled : disabledInputs.fechaIni,
        id: "fechaIni",
        value: state.fechaIni,
        error: errorState.fechaIni.error,
        success: state.fechaIni && !errorState.fechaIni.error ? true : undefined,
        helperText: errorState.fechaIni.helperText,
        // inputRef: fechaIniRef,
        placeholder: "Rango inicial de descarga *",
        grid: { md: 6, lg: 3 },
        tipo: "datetimepicker",
        onlyDate: true,
        onChange: (value) => {
            if(typeof value !== "object") return;
            handleChange(value, "fechaIni");
        }
    },{
        disabled: disabled ? disabled : disabledInputs.fechaFin,
        id: "fechaFin",
        value: state.fechaFin,
        error: errorState.fechaFin.error,
        success: state.fechaFin && !errorState.fechaFin.error ? true : undefined,
        helperText: errorState.fechaFin.helperText,
        placeholder: "Rango final de descarga *",
        grid: { md: 6, lg: 3 },
        tipo: "datetimepicker",
        onlyDate: true,
        onChange: (value) => {
            if(typeof value !== "object") return;
            handleChange(value, "fechaFin");
        }
    },{
        disabled: disabled ? disabled : disabledInputs.tipo,
        id: "tipo",
        value: state.tipo,
        error: errorState.tipo.error,
        success: state.tipo && !errorState.tipo.error ? true : undefined,
        helperText: errorState.tipo.helperText,
        title: "Tipo de documento *",
        placeholder: "Seleccionar el tipo de documento",
        tipo: "select",
        grid: { md: 6, lg: 3 },
        data: seleccionables.tipoDocumento,
        onChange: ({target:{value}}) => setState({ ...state, tipo: value})
    },{
        disabled: disabled ? disabled : disabledInputs.tipoSolicitud,
        id: "tipoSolicitud",
        value: state.tipoSolicitud,
        error: errorState.tipoSolicitud.error,
        success: state.tipoSolicitud && !errorState.tipoSolicitud.error ? true : undefined,
        helperText: errorState.tipoSolicitud.helperText,
        title: "Tipo de solicitud *",
        placeholder: "Seleccionar el tipo de solicitud",
        tipo: "select",
        grid: { md: 6, lg: 3 },
        data: seleccionables.tipoSolicitud,
        onChange: ({target:{value}}) => setState({ ...state, tipoSolicitud: value})
    },{
        disabled: disabled ? disabled : disabledInputs.tipoSolicitud,
        id: "tipoComprobante",
        value: state.tipoComprobante,
        error: errorState.tipoComprobante.error,
        success: state.tipoComprobante && !errorState.tipoComprobante.error ? true : undefined,
        helperText: errorState.tipoComprobante.helperText,
        title: "Tipo de comprobante *",
        placeholder: "Seleccionar el tipo de comprobante",
        tipo: "select",
        grid: { md: 6, lg: 3 },
        data: seleccionables.aTipoComprobante,
        onChange: ({target:{value}}) => setState({ ...state, tipoComprobante: value})
    },{
        disabled: disabled ? disabled : disabledInputs.tipoSolicitud,
        id: "estadoComprobante",
        value: state.estadoComprobante,
        error: errorState.estadoComprobante.error,
        success: state.estadoComprobante && !errorState.estadoComprobante.error ? true : undefined,
        helperText: errorState.estadoComprobante.helperText,
        title: "Estado comprobante *",
        placeholder: "Seleccionar el estado del comprobante",
        tipo: "select",
        grid: { md: 6, lg: 3 },
        data: seleccionables.aEstadoComprobante,
        onChange: ({target:{value}}) => setState({ ...state, estadoComprobante: value})
    },{
        disabled: disabled ? disabled : disabledInputs.tipoSolicitud,
        id: "complemento",
        value: state.complemento,
        error: errorState.complemento.error,
        success: state.complemento && !errorState.complemento.error ? true : undefined,
        helperText: errorState.complemento.helperText,
        title: "Complemento *",
        placeholder: "Seleccionar el complemento",
        tipo: "select",
        grid: { md: 6, lg: 6 },
        data: seleccionables.aComplementos,
        onChange: ({target:{value}}) => setState({ ...state, complemento: value})
    },{
        disabled: disabled ? disabled : disabledInputs.rfcTercero,
        id: "rfcTercero",
        value: state.rfcTercero,
        error: errorState.rfcTercero.error,
        success: state.rfcTercero && !errorState.rfcTercero.error ? true : undefined,
        helperText: errorState.rfcTercero.helperText,
        inputRef: rfcTerceroRef,
        title: "RFC Tercero",
        placeholder: "Capture el RFC Tercero",
        grid: { md: 4, lg: 4 },
        onChange: ({target:{value}}) => handleChange(value.toUpperCase(), "rfcTercero"),
        onKeyDown: () => { return; }
    },{
        disabled: disabled ? disabled : disabledInputs.folioFiscal,
        id: "folioFiscal",
        value: state.folioFiscal,
        error: errorState.folioFiscal.error,
        success: state.folioFiscal && !errorState.folioFiscal.error ? true : undefined,
        helperText: errorState.folioFiscal.helperText,
        inputRef: folioFiscalRef,
        title: "Folio Fiscal",
        placeholder: "Capture el Folio Fiscal",
        grid: { md: 4, lg: 4 },
        onChange: ({target:{value}}) => handleChange(value.toUpperCase(), "folioFiscal"),
        onKeyDown: () => { return; }
    }];

    const accionesFormulario = [{
        loader,
        disabled,
        icono: NextWeekIcon,
        color: info,
        descripcion: "Enviar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            mensajeCorrecto: `La solicitud se ${state.solicitudID ? "generó" : "verificó"} correctamente`,
            asyncCallback: ftGuardar,
            setErrorToken
        })
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }){
        return handleErrorInputText({ cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState });
    }

    function handleValidaciones({ empresaID, cer, cerFile, key, keyFile, password, emisor, receptor, fechaIni, fechaFin }) {

        let error;
        let validaciones = [{
            condicion: !rgxNumeros.test(empresaID), keyError: "empresaID",
            mensajeError: "Para que podamos funcionar bien necesitamos que selecciones una empresa"
        }, {
            condicion: !/\.cer$/.test(cer) || !cerFile, keyError: "cer",
            mensajeError: "Es necesario subir un archivo con la extensión .cer"
        }, {
            condicion: !/\.key$/.test(key) || !keyFile, keyError: "key",
            mensajeError: "Es necesario subir un archivo con la extensión .key"
        }, {
            condicion: !password || trim(password) === "",
            ref: passwordRef, keyError: "password",
            mensajeError: "Para que podamos funcionar bien es necesario capturar la contraseña de clave privada"
        }, {
            condicion: (emisor || trim(emisor) !== "") && !rgxRFC.test(emisor), ref: emisorRef, keyError: "emisor",
            mensajeError: "El RFC capturado lamentablemente no tiene un formato correcto"
        }, {
            condicion: (receptor || trim(receptor) !== "") && !rgxRFC.test(receptor), ref: receptorRef, keyError: "receptor",
            mensajeError: "El RFC capturado lamentablemente no tiene un formato correcto"
        }, {
            condicion: !moment(fechaIni).isValid(), keyError: "fechaIni",
            mensajeError: "La fecha inicial no tiene un formato válido"
        }, {
            condicion: !moment(fechaFin).isValid(), keyError: "fechaFin",
            mensajeError: "La fecha final no tiene un formato válido"
        }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if(error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if(error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { empresaID, cer, cerFile, key, keyFile, password, emisor, receptor, fechaIni, fechaFin, IdSolicitud, tipo, tipoSolicitud,tipoComprobante,estadoComprobante,rfcTercero,complemento,folioFiscal } = state;
             console.log(state,'state');
            let error = await handleValidaciones({ empresaID, cer, cerFile, key, keyFile, password, emisor, receptor, fechaIni, fechaFin });

            if(error) return error;

            let formato = "YYYY-MM-DDThh:mm:ss";
            let foromatoRegexp = /T([0][0-9]|1[0-2]):([0-5][0-9]):([0-5][0-9])$/g;

            let data = {
                empresaID, password, tipo, tipoSolicitud,
                cer: cerFile, key: keyFile,
                fechaIni: fechaIni.format(formato).replace(foromatoRegexp, 'T00:00:00'),
                fechaFin: fechaFin.format(formato).replace(foromatoRegexp, 'T23:59:59'),
                tipoComprobante:tipoComprobante,
                estadoComprobante:estadoComprobante,
                RfcACuentaTerceros:rfcTercero,
                Complemento:complemento,
                cUUID:folioFiscal
            };

            if(emisor) data = { ...data, emisor };
            if(receptor) data = { ...data, receptor };
            if(IdSolicitud) data = { ...data, IdSolicitud };

            await descargaMasivaSAT({ data, token });

        }
        return ftGuardar();
    }

    function descargarDocumentos({ solicitudID, IdSolicitud }) {
        async function ftDescargarDocuments() {
            try {

                setAlert({
                    title: "¡Estámos en ello!",
                    descripcion: "Si estás viendo este mensaje, es posible que la descarga del registro este tardando más de lo esperado, te pedimos un poco de paciencia",
                    onConfirm: () => setAlert(null),
                    tipo: success,
                    showConfirm: false,
                    showCancel: false
                });

                await dowloadZIP({ data: { solicitudID }, token }, IdSolicitud);

                setAlert(null);

            } catch({message}) {
                setAlert({
                    descripcion: `Ocurrió un error al obtener el archivo con los documentos; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
                setErrorToken(message);
            }
        } ftDescargarDocuments();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setDisabledInputs({ ...cleanDisabledInputs });
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function handleChangeFiles({files, value}, key) {
        setState({
            ...state,
            [key]: value,
            [`${key}File`]: files[0]
        });
    }

    function ftEffect() {
        async function effect() {
            try {

                const tipoDocumento = [
                    { value: "cfdi", label: "CFDI"},
                    { value: "retenciones", label: "Retenciones"}
                ];
                const tipoSolicitud = [
                    { value: "CFDI", label: "CFDI"},
                    { value: "Metadata", label: "Metadata"}
                ];

                const aComplementos = [{ value: null, label: 'Todos'},
                    { value: "acreditamientoieps10", label: 'acreditamientoieps10'},
                    { value: "aerolineas", label: 'aerolineas'},
                    { value: "certificadodedesctruccion", label: 'certificadodedesctruccion'},
                    { value: "cfdiregistrofiscal", label: 'cfdiregistrofiscal'},
                    { value: "comercioexterior10", label: 'comercioexterior10'},
                    { value: "comercioexterior11", label: 'comercioexterior11'},
                    { value: "comprobante", label:  'comprobante'},
                    { value: "consumodecombustibles", label:  'consumodecombustibles'},
                    { value: "consumodecombustibles11", label: 'consumodecombustibles11'},
                    { value: "detallista", label: 'detallista'},
                    { value: "divisas", label: 'divisas'},
                    { value: "donat11", label: 'donat11'},
                    { value: "ecc11", label: 'ecc11'},
                    { value: "ecc12", label: 'ecc12'},
                    { value: "gastoshidrocarburos10", label: 'gastoshidrocarburos10'},
                    { value: "iedu", label: 'iedu'},
                    { value: "implocal", label: 'implocal'},
                    { value: "ine11", label: 'ine11'},
                    { value: "ingresoshidrocarburos", label: 'ingresoshidrocarburos'},
                    { value: "leyendasfisc", label: 'leyendasfisc'},
                    { value: "nomina11", label: 'nomina11'},
                    { value: "nomina12", label: 'nomina12'},
                    { value: "notariospublicos", label: 'notariospublicos'},
                    { value: "obrasarteantiguedades", label: 'obrasarteantiguedades'},
                    { value: "pagoenespecie", label: 'pagoenespecie'},
                    { value: "pagos10", label: 'pagos10'},
                    { value: "pfic", label: 'pfic'},
                    { value: "renovacionysustitucionvehiculos", label: 'renovacionysustitucionvehiculos'},
                    { value: "servicioparcialcontruccion", label: 'servicioparcialcontruccion'},
                    { value: "spei", label: 'spei'},
                    { value: "terceros11", label: 'terceros11'},
                    { value: "turistapasajeroextranjero", label: 'turistapasajeroextranjero'},
                    { value: "valesdedespensa", label: 'valesdedespensa'},
                    { value: "vheiculousado", label: 'vheiculousado'},
                    { value: "ventavehiculos11", label: 'ventavehiculos11'},
                    { value: "arrendamientofideicomiso", label: 'arrendamientofideicomiso'},
                    { value: "divisendos", label: 'divisendos'},
                    { value: "enajenaciondeacciones", label: 'enajenaciondeacciones'},
                    { value: "dideicomisonoempresarial", label: 'dideicomisonoempresarial'},
                    { value: "interesesintereseshipotecarios", label: 'interesesintereseshipotecarios'},
                    { value: "operacionesconderivados", label: 'operacionesconderivados'},
                    { value: "pagosaextranjeros", label: 'pagosaextranjeros'},
                    { value: "planesderetiro", label: 'planesderetiro'},
                    { value: "planesderetiro11", label: 'planesderetiro11'},
                    { value: "premios", label: 'premios'},
                    { value: "retencionpago1", label: 'retencionpago1'},
                    { value: "sectorfinanciero", label: 'sectorfinanciero'},
                    { value: "serviciosplataformastecnologicas10", label: 'serviciosplataformastecnologicas10'}];

                const aTipoComprobante = [{ value: null, label: 'Todos'},
                                        { value: "I", label: 'I = Ingresos'},
                                        { value: "E", label: 'E = Egresos'},
                                        { value: "T", label: 'T = Traslados'},
                                        { value: "N", label: 'N = Nomina'},
                                        { value: "P", label: 'P = Pago'}];

                const aEstadoComprobante = [{ value: null, label: 'Todos'},
                                        { value: "1", label: 'Vigentes'},
                                        { value: "0", label: 'Cancelados'}]

                const enEstatus = await EnumEstatus(token);

                const estadoSolicitud = await seleccionEstadoSolicitud(token);

                let proveedores = await seleccionProveedores(token);
                let estatus = await selectEnumEstatus(token);
                let tipoUsuarios = await selectEnumTipoUsuarios(token);

                let empresasUsuario = await selectEmpresasUsuario({
                    input: { usuarioID, empresaID: null, estatusID: enEstatus.ACTIVO.toNumber() }
                }, token);
                
                let empresaIDUsuario = await selectEmpresaIDUsuario({
                    input: { usuarioID, empresaID: null, estatusID: enEstatus.ACTIVO.toNumber() }
                }, token);
                
                empresaIDUsuario = empresaIDUsuario.map(empresa => ({label:empresa.label,value:empresa.label}))
                
                let empresas = await seleccionEmpresa({
                    estatusID: enEstatus.ACTIVO.toNumber()
                }, token);

                setSeleccionables({ proveedores, estatus, tipoUsuarios, empresas, tipoDocumento, tipoSolicitud, empresasUsuario, estadoSolicitud,aComplementos,aTipoComprobante,aEstadoComprobante, empresaIDUsuario });

            } catch({message}) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
                setErrorToken(message);
            }
        } effect();
    }

    useEffect(ftEffect, [actualizar]);

    return(!tokenError && seleccionables.estadoSolicitud ? <>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaSolicitudes}
            parametrosFijos={{ usuarioID }}
            token={token}
            showHelp={true}
            mensajeHelp={<><p>Es posible que en algunas ocasiones la información no pueda almacenarse para su descarga posterior, por lo que le recomendamos que siempre acepte guardar la documentación.</p><p>**** Recuerda que los documentos solo se almacenan por 72 horas ****</p></>}
        />
        <FormularioModal
            open={open}
            title={`${state.solicitudID ? "Verificar estatus de" : "Solicitar"} descarga`}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
        />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        /> }
    </> : <Loader />);

}

export default Solicitudes;
