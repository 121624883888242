import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';
import { TABLA_PROVEEDORES, GET_PROVEEDORES, GET_ALL_PROVEEDORES, SEND_EMAIL_PROVEEDOR } from '../index';

export const tablaProveedores = async (variables, token) => {
    try {

        let { filterProveedores } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_PROVEEDORES
        });

        return filterProveedores;

    } catch({message}) { throw new Error(message); }
}

export const getProveedores = async (variables, token) => {
    try {

        let { getProveedor } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_PROVEEDORES
        });

        return getProveedor;

    } catch({message}) { throw new Error(message); }
}

export const getAllProveedores = async (token) => {
    try {

        let { getAllProveedor } = await FetchAPI.graphql({
            token,
            url: urlServidor,
            query: GET_ALL_PROVEEDORES
        });

        return getAllProveedor;

    } catch({message}) { throw new Error(message); }
}

export const seleccionProveedores = async (token) => {
    try {

        let allProveedores = await getAllProveedores(token);

        if(!Array.isArray(allProveedores)) throw new Error("No se encontraron registros");

        return allProveedores.map(({ proveedorID:value, nombre:label }) => ({ value, label }));

    } catch({message:label}) {
        return [{
            label,
            value: "",
            disabled: true
        }];
    }
}

export const sendEmailProveedor = async (variables, token) => {
    try {

        let { sendEmailProveedor } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: SEND_EMAIL_PROVEEDOR
        });

        return sendEmailProveedor;

    } catch({message}) { throw new Error(message); }
}
