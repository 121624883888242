import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';
import { ADD_USUARIO, UPDATE_USUARIO, DELETE_USUARIO, ASIGNAR_EMPRESAS_USUARIO } from '../index';

export const addUsuario = async (variables, token) => {
    try {

        let { addUsuario } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_USUARIO
        });

        return addUsuario;

    } catch({message}) { throw new Error(message); }
};

export const updateUsuario = async (variables, token) => {
    try {

        let { updateUsuario } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_USUARIO
        });

        return updateUsuario;

    } catch({message}) { throw new Error(message); }
};

export const deleteUsuario = async (variables, token) => {
    try {

        let { deleteUsuario } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: DELETE_USUARIO
        });

        return deleteUsuario;

    } catch({message}) { throw new Error(message); }
};

export const asignarEmpresasUsuario = async (variables, token) => {
    try {

        let { asignarEmpresasUsuario } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ASIGNAR_EMPRESAS_USUARIO
        });

        return asignarEmpresasUsuario;

    } catch({message}) { throw new Error(message); }
};
