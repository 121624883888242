// Componentes
import Proveedores from '../Proveedores';
import Empresas from '../Empresas';
import Usuarios from '../Usuarios';
import Solicitudes from '../Solicitudes';
import Documentos from '../Documentos';
import Reportes from '../Reportes';

// Iconos
import CatalogosIcon from '@material-ui/icons/FolderSpecial';
import ProveedorIcon from '@material-ui/icons/BusinessCenter';
import EmpresaIcon from '@material-ui/icons/Business';
import UsuarioIcon from '@material-ui/icons/People';
import SolicitudIcon from '@material-ui/icons/MarkunreadMailbox';
import TopicIcon from '@mui/icons-material/Topic';

export default [{
    colapsar: true,
    titulo: "Catálogos",
    icono: CatalogosIcon,
    agrupador: "catalogosCollapse",
    permisos: {
        administrador: true,
        usuario: false
    },
    vistas: [{
        titulo: "Proveedores",
        ruta: "/proveedores",
        componente: Proveedores,
        icono: ProveedorIcon,
        layout: "/cat",
        permisos: {
            administrador: true,
            usuario: false
        },
    },{
        titulo: "Empresas",
        ruta: "/empresas",
        componente: Empresas,
        icono: EmpresaIcon,
        layout: "/cat",
        permisos: {
            administrador: true,
            usuario: false
        },
    },{
        titulo: "Usuarios",
        ruta: "/usuarios",
        componente: Usuarios,
        icono: UsuarioIcon,
        layout: "/cat",
        permisos: {
            administrador: true,
            usuario: false
        },
    }]
},{
    titulo: "Solicitudes",
    ruta: "/solicitudes",
    componente: Solicitudes,
    icono: SolicitudIcon,
    layout: "/gen"
},
{
    titulo: "Documentos",
    ruta: "/documentos",
    componente: Documentos,
    icono: TopicIcon,
    layout: "/gen"
},
{
    titulo: "Reportes",
    ruta: "/reportes",
    componente: Reportes,
    icono: TopicIcon,
    layout: "/gen"
}
];
