import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';
import { GET_USUARIO, TABLA_USUARIOS, GET_EMPRESAS_USUARIO } from '../index';

export const getUsuario = async (variables, token) => {
    try {

        let { getUsuario } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_USUARIO
        });

        return getUsuario;

    } catch({message}) { throw new Error(message); }
}

export const tablaUsuarios = async (variables, token) => {
    try {

        let { filterUsuarios } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_USUARIOS
        });

        return filterUsuarios;

    } catch({message}) { throw new Error(message); }
}

export const getEmpresasUsuario = async (variables, token) => {
    try {

        let { getEmpresasUsuario } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_EMPRESAS_USUARIO
        });

        return getEmpresasUsuario;

    } catch({message}) { throw new Error(message); }
}

export const selectEmpresasUsuario = async (variables, token) => {
    try {

        let { getEmpresasUsuario } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_EMPRESAS_USUARIO
        });

        if(!Array.isArray(getEmpresasUsuario)) throw new Error("No se encontraron registros");

        return getEmpresasUsuario.map(({ empresaID:value, rfc, razonSocial}) => ({ value, label: `${razonSocial} - ${rfc}` }));

    } catch({message:label}) {
        return [{
            label,
            value: "",
            disabled: true
        }];
    }
}

export const selectEmpresaIDUsuario = async (variables, token) => {
    try {

        let { getEmpresasUsuario } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_EMPRESAS_USUARIO
        });

        if(!Array.isArray(getEmpresasUsuario)) throw new Error("No se encontraron registros");

        return getEmpresasUsuario.map(({ empresaID:value, rfc, razonSocial}) => ({ value, label: razonSocial}));

    } catch({message:label}) {
        return [{
            label,
            value: "",
            disabled: true
        }];
    }
}
