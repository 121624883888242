import { urlServidor } from '../helpers';
import { saveAs } from 'file-saver';

const fetchDescargaMasiva = async ({ data, token, method = "POST", url }) => {
    try {

        let formData = new FormData(), file = false;
        let keys = Object.keys(data);
        keys.forEach(key => { formData.append(key, data[key]); });

        let rh = await fetch( `${urlServidor}/${url}`, {
            method,
            body: formData,
            headers: { token }
        }).then(res => {
            file = !res.headers.get("Content-Type");
            return file ? res.blob() : res.json();
        });

        if(rh.ok === false) throw new Error(rh.mensaje);
        if(file) saveAs(rh, `${data.IdSolicitud}.zip`);

        return !file && rh;

    } catch({message}) { throw new Error(message); }
};

export const descargaMasivaSAT = async ({ data, token }) => {
    try { return await fetchDescargaMasiva({ data, token, url: "descargamasiva" }); }
    catch({message}) { throw new Error(message); }
};

export const generarPFX = async ({ data, token }) => {
    try { return await fetchDescargaMasiva({ data, token, url: "generarpfx" }); }
    catch({message}) { throw new Error(message); }
};

export const dowloadZIP = async ({ data, token }, IDsolicitudSAT) => {

    let formData = new FormData();
    let keys = Object.keys(data);
    keys.forEach(key => { formData.append(key, data[key]); });

    let zip = await fetch(`${urlServidor}/descargarZIP`, {
        method: "POST",
        body: formData,
        headers: { token }
    }).then(res => {
        if(!res.ok) return res.json();
        return res.blob();
    });

    if(zip.ok === false) throw new Error(zip.mensaje);

    saveAs(zip, `${IDsolicitudSAT}.zip`);

};


export const bulkXML = async (data, token) =>{

    let formData = new FormData();
    let keys = Object.keys(data);
    keys.forEach(key => { formData.append(key, data[key]); });

    let archivo = await fetch(`${urlServidor}/bulkXML`, {
        method: "POST",
        headers: {token},
        body: formData
    }).then(res => {
        if(!res.ok) return res.json();
        return res.blob();
    }).then((res) =>{
        return(res)
    }
    )

    return archivo;


}


export const validacionEstado = async ({ xml, token }) => {
    try { return await fetchValidacionEstado({ xml, token, url: "validacionEstado" }); }
    catch({message}) { throw new Error(message); }
};


export const fetchValidacionEstado = async ({ xml, token, url, method = "POST"}) => {
    
        let formData = new FormData();
        formData.append("xml", xml);
        let requestOptions = {
            method: method,
            headers: { token },
            body: formData
        };

           let data =  await fetch( `${urlServidor}/${url}`, requestOptions)
           .then((res) => {
               return res.json();
            }).then((res) =>{
                return(res)
            }
            )
            return data;
        
}