import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';
import { GET_SOLICITUD, GET_ESTADO_SOLICITUD, TABLA_SOLICITUD } from '../index';

export const getSolicitud = async (variables, token) => {
    try {

        let { getSolicitud } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_SOLICITUD
        });

        return getSolicitud;

    } catch({message}) { throw new Error(message); }
}

export const getEstadoSolicitud = async (token) => {
    try {

        let { getEstadoSolicitud } = await FetchAPI.graphql({
            token,
            url: urlServidor,
            query: GET_ESTADO_SOLICITUD
        });

        return getEstadoSolicitud;

    } catch({message}) { throw new Error(message); }
}

export const seleccionEstadoSolicitud = async (token) => {
    try {

        let allEstadoSolicitud = await getEstadoSolicitud(token);

        if(!Array.isArray(allEstadoSolicitud)) throw new Error("No se encontraron registros");

        return allEstadoSolicitud.map(({ numero:value, descripcion:label }) => ({ value, label }));

    } catch({message:label}) {
        return [{
            label,
            value: "",
            disabled: true
        }];
    }
}

export const tablaSolicitudes = async (variables, token) => {
    try {

        let { filterSolicitudes } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_SOLICITUD
        });

        return filterSolicitudes;

    } catch({message}) { throw new Error(message); }
}
