import React from 'react';
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import CustomInput from '../../../components/CustomInput/CustomInput.js';

// style for this view
import styles from "../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);

export default function Input(props) {

    const classes = useStyles();

    let { title, value, id, success = false, error = false, posicionIcono = "end", icono, helperText, textAlign = "left",
        fullWidth = true, disabled = false, onChange, onKeyDown, placeholder = "", inputProps = {}, inputRef, onClickIcono, multiline = false } = props

    let componenteIcono = (icono ? icono : (error ? <HighlightOffIcon className={classes.danger} /> : ( success ? <CheckCircleOutlineIcon className={classes.success} /> : null )));

    let styleAdornment = onClickIcono ? { cursor: "pointer" } : {};
    let iconAction = onClickIcono ? (e) => onClickIcono(e) : () => { return; };

    return (<CustomInput
        style={{ margin: "0px" }}
        labelText={title}
        id={id}
        value={value}
        success={success}
        error={error}
        formControlProps={{ fullWidth }}
        helperText={helperText}
        textAlign={textAlign}
        inputProps={{
            startAdornment: (posicionIcono !== "end" && (icono || (success || error))) && (<InputAdornment style={styleAdornment} onClick={iconAction} position="start">{componenteIcono}</InputAdornment>),
            endAdornment: (posicionIcono === "end" && (icono || (success || error))) && (<InputAdornment style={styleAdornment} onClick={iconAction} position="end">{componenteIcono}</InputAdornment>),
            placeholder,
            disabled,
            onKeyDown,
            multiline,
            ...inputProps
        }}
        inputRef={inputRef}
        onChange={onChange}
    />);

}

Input.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.any,
    posicionIcono: PropTypes.string,
    textAlign: PropTypes.oneOf([
        "left",
        "right",
        "center"
    ]),
    placeholder: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    error: PropTypes.bool,
    success: PropTypes.bool,
    disabled: PropTypes.bool,
    helperText: PropTypes.string,
    fullWidth: PropTypes.bool,
    icono: PropTypes.node,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onClickIcono: PropTypes.func
};
