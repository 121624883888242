import { urlServidor } from '../helpers';

const fetchProveedor = async ({ data, token, method = "POST" }) => {
    try {

        let formData = new FormData();
        let keys = Object.keys(data);
        keys.forEach(key => { formData.append(key, data[key]); });

        let rh = await fetch( `${urlServidor}/proveedor`, {
            method,
            body: formData,
            headers: { token }
        }).then(res => res.json());

        if(!rh.ok) throw new Error(rh.mensaje);

        return rh;

    } catch({message}) { throw new Error(message); }
};

export const addProveedor = async ({ data, token }) => {
    try { return await fetchProveedor({ data, token }); }
    catch({message}) { throw new Error(message); }
};

export const updateProveedor = async ({ data, token }) => {
    try { return await fetchProveedor({ data, token, method: "PUT" }); }
    catch({message}) { throw new Error(message); }
};
