
export const GET_USUARIO = `
    query getUsuario($usuarioID: ID!) {
        getUsuario(id: $usuarioID) {
            usuarioID
            nombreUsuario
            proveedorID
            nombreProveedor
            aliasProveedor
            logotipoProveedor
            email
            estatusID
            estatus
            estatusDescripcion
            tipoID
            tipo
            tipoDescripcion
            fechaAlta
            empresasIDs
        }
    }
`;

export const TABLA_USUARIOS = `
    query filterUsuarios($filtro: InputFilterUsuario!) {
        filterUsuarios(input: $filtro) {
            paginas
            registros
            listado {
                usuarioID
                nombreUsuario
                email
                nombreProveedor
                estatus
                tipo
                fechaAlta
            }
        }
    }
`;

export const GET_EMPRESAS_USUARIO = `
    query getEmpresasUsuario($input: InputGetEmpresasUsuario!) {
        getEmpresasUsuario(input: $input) {
            empresaID
            rfc
            razonSocial
        }
    }
`;
