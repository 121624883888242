import Enums from '@arskang/enumsjs';
import { enumEstatus, enumTipoUsuarios } from '../querys/Enums/metodos';

const ftGenerarEnums = async (metodo, token) => {

    let dataEnum = await metodo(token);

    return Enums.set(dataEnum);

};

// Listado de Enumeraciones
// Se utiliza la función y se le manda el método creado en EnumService para consultar los datos
export const EnumEstatus = (token) => ftGenerarEnums(enumEstatus, token);
export const EnumTipoUsuarios = (token) => ftGenerarEnums(enumTipoUsuarios, token);

// Ejemplo de uso:
/*

// Se importa la enumeración

import { EnumExample } from '../enums';

// Se asigna a una variable, debe estar dentro de un método asyncrono

const en = await EnumExample;

// Ya puede utilizarse

en.ADMIN.getName() || en.ADMIN.toString(); // retorna 'Admin'
en.ADMIN.getValue() || en.ADMIN.toNumber();; //retorna 1
en.ADMIN.getDescription(); // retorna 'Administrador'

// Revisar .isEqual() y .convert() en:
// https://www.npmjs.com/package/@arskang/enumsjs

*/
