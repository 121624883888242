import { useState, useEffect } from 'react';

import { EnumTipoUsuarios } from '../enums';

export function useTipoUsuario(tipoID, token) {

    const [tipo, setTipo] = useState({});

    function ftEffect() {
        async function effect() {
            const tipoUsuario = await EnumTipoUsuarios(token);
            if(tipoUsuario.ADMINISTRADOR.isEqual(tipoID)) setTipo({ tipoAdministrador: true });
            if(tipoUsuario.MODERADOR.isEqual(tipoID)) setTipo({ tipoModerador: true });
            if(tipoUsuario.USUARIO.isEqual(tipoID)) setTipo({ tipoUsuario: true });
        } effect();
    }

    useEffect(ftEffect, [tipoID]);

    return tipo;

}
