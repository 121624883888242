
export const GET_SOLICITUD = `
    query getSolicitud($solicitudID: ID!) {
        getSolicitud(id: $solicitudID) {
            solicitudID
            empresaID
            rfc
            razonSocial
            guardarFIEL
            caducidadCertificado
            fechaIni
            fechaFin
            emisor
            receptor
            tipoSolicitud
            tipo
            IdSolicitud
            codigoSolicitud
            mensajeCodigoSolicitud
            observacionesCodigoSolicitud
            estadoSolicitud
            mensajeEstadoSolicitud
            fechaAlta
            tipoComprobante
            estadoComprobante
            rfcPorTerceros
            complemento
            folioFiscal
            mensaje
        }
    }
`;

export const GET_ESTADO_SOLICITUD = `
    query getEstadoSolicitud {
        getEstadoSolicitud {
            numero
            descripcion
        }
    }
`;

export const TABLA_SOLICITUD = `
    query filterSolicitudes($filtro: InputFilterSolicitud!) {
        filterSolicitudes(input: $filtro){
            registros
            paginas
            listado {
                solicitudID
                empresaID
                razonSocial
                fechaIni
                fechaFin
                emisor
                receptor
                tipoSolicitud
                tipo
                IdSolicitud
                estadoSolicitud
                mensajeEstadoSolicitud
                zip
                fechaAlta
                tipoComprobante
                estadoComprobante
                rfcPorTerceros
                complemento
                folioFiscal
                mensaje
            }
        }
    }
`;
