
export const rgxCorreo = /^([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}(\.[a-z]{2,3})?$/;

export const rgxPassword = /^(?=(.*[\d]){1,})(?=(.*[a-zñ]){1,})(?=(.*[A-ZÑ]){1,})(?=(.*[@#$%¡!*=<>()|¿?&-]){1,})(?:[\da-zA-ZÑñ=@#$%¡!*<>()|¿?&-]){8,30}$/;

export const rgxNumeros = /^[0-9]+$/;

export const rgxRFC = /^([A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A])$/;

export const rgxUrl = /^[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
