import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';



export const DOCUMENTO_ESTADO =`
    mutation documentosEstado($input: InputDocumentosEstado) {
        documentosEstado(input: $input) {
            doctoID
            estadoSAT
            mensajeSAT
        }
    }
`;


export const documentosEstado = async (variables, token) => {
    try {

        let { documentosEstado } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: DOCUMENTO_ESTADO
        });

        return documentosEstado;

    } catch({message}) { throw new Error(message); }
};
