
export const ADD_EMPRESA = `
    mutation addEmpresa($input: InputAddEmpresa!) {
        addEmpresa(input: $input) {
            empresaID
            fechaAlta
        }
    }
`;

export const UPDATE_EMPRESA =`
    mutation updateEmpresa($input: InputUpdateEmpresa!) {
        updateEmpresa(input: $input) {
            empresaID
            fechaAlta
        }
    }
`;

export const DELETE_EMPRESA = `
    mutation deleteEmpresa($empresaID: ID!) {
        deleteEmpresa(id: $empresaID)
    }
`;
