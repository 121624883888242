import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../../helpers';
import { TABLA_DOCUMENTOS, GET_ALL_TIPOSDOCUMENTOS, GET_ALL_DIVISAS, GET_DOCUMENTOS, GET_ALL_DOCUMENTOS} from '../index';

export const getDocumento = async (variables, token) => {
    try {

        let { getDocumentos } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_DOCUMENTOS
        });       
        return getDocumentos;

    } catch({message}) { throw new Error(message); }
}

export const getAllDocumento = async (variables, token) => {
    try {

        let { getAllDocumentos } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_DOCUMENTOS
        });       
        return getAllDocumentos;

    } catch({message}) { throw new Error(message); }
}

export const getAllTipoDocumento = async (variables, token) => {
    try {

        let { GetAllTipoDocto } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_TIPOSDOCUMENTOS
        });       
        return GetAllTipoDocto;

    } catch({message}) { throw new Error(message); }
}

export const seleccionTiposDocumento = async (variables, token) => {
    try {

        let allTiposDocumentos = await getAllTipoDocumento(variables, token);

        if(!Array.isArray(allTiposDocumentos)) throw new Error("No se encontraron registros");

        return allTiposDocumentos.map(({ tipoDoctoID:value, tipoDocto:label }) => ({ value, label }));

    } catch({message:label}) {
        return [{
            label,
            value: "",
            disabled: true
        }];
    }
}

export const seleccionDivisas = async (variables, token) => {
    try {

        let { GetAllDivisas } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_DIVISAS
        });  

        if(!Array.isArray(GetAllDivisas)) throw new Error("No se encontraron registros");

        return GetAllDivisas.map(({ divisa:value, divisa:label }) => ({ value, label }));

    } catch({message:label}) {
        return [{
            label,
            value: "",
            disabled: true
        }];
    }
}

export const seleccionEstadoVigencia = async (variables, token) => {
    try {

        let { GetEstadoVigencia } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_DIVISAS
        });  

        if(!Array.isArray(GetEstadoVigencia)) throw new Error("No se encontraron registros");

        return GetEstadoVigencia.map(({ estadoVigencia:value, estadoVigencia:label }) => ({ value, label }));

    } catch({message:label}) {
        return [{
            label,
            value: "",
            disabled: true
        }];
    }
}

export const tablaDocumentos = async (variables, token) => {
    try {
        let { filterDocumentos } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_DOCUMENTOS
        });
        
        return filterDocumentos;

    } catch({message}) { throw new Error(message); }
}
