import React from 'react'

import CambiarPassword from '../../../_layout/masterComponents/Login/CambiarPassword';

import loginFondo from '../../../_layout/assets/img/banner_fondo_descargaxml.png';

import { rgxPassword } from '../../../helpers/regexp';

import { isToken } from '../../../querys/Login/metodos';
import { updatePassword } from '../../../mutations/Login/metodos';

function ActualizarPassword() {

    return (
        <CambiarPassword
            isToken={isToken}
            loginFondo={loginFondo}
            rgxPassword={rgxPassword}
            updatePassword={updatePassword}
        />
    )

}

export default ActualizarPassword;
