
// Url's
export const urlServidor = process.env.REACT_APP_URL_SERVER || "http://localhost:3500";//https://tytsoftwaredemexico.com.mx:5000

// Semilla
export const seed = process.env.SEED || "Y2FwZXJ1Y2l0YXJvamE=";
export const seedBackend = process.env.SEED_BACKEND || "U0VFRGdydXBvSnVsaW9AQA==";

// LocalStorage
export const TOKEN = "sesionToken";
export const USUARIO = "datosUsuario";
