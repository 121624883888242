import React, { useState, useRef, useEffect } from 'react'
import { Validaciones } from '@renedelangel/helpers';

// Redux
import { useSelector } from "react-redux";

// Custom hooks
import { useErrorToken } from '../../hooks/errores';

// master components
import Tabla from '../../_layout/masterComponents/Tabla';
import FormularioModal from '../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';

import { NetworkError } from "../../_layout/genericComponents/Metodos";

import { getUsuario, tablaUsuarios } from '../../querys/Usuarios/metodos';
import { seleccionProveedores } from '../../querys/Proveedores/metodos';
import { seleccionEmpresa } from '../../querys/Empresas/metodos';
import { selectEnumEstatus, selectEnumTipoUsuarios } from '../../querys/Enums/metodos'
import { addUsuario, updateUsuario, deleteUsuario, asignarEmpresasUsuario } from '../../mutations/Usuarios/metodos';

import { rgxNumeros, rgxCorreo, rgxPassword } from '../../helpers/regexp';
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar } from '../../_layout/helpers/handles';
import { info, danger, success, warning, primary } from '../../_layout/helpers/colores';

import { EnumEstatus } from '../../enums';

const { trim } = Validaciones;

function Usuarios() {

    const { token } = useSelector(state => state.login);

    const cleanState = { usuarioID: null, proveedorID: "", nombreUsuario: "", email: "", password: "", confirmPassword: "", estatusID: "", tipoID: "" };
    const cleanStateMultiple = { usuarioID: null, empresasIDs: [] };
    const cleanErrorState = {
        proveedorID: { error: false, helperText: ""  },
        nombreUsuario: { error: false, helperText: ""  },
        email: { error: false, helperText: "" },
        password: { error: false, helperText: "" },
        confirmPassword: { error: false, helperText: "" },
        estatusID: { error: false, helperText: ""  },
        tipoID: { error: false, helperText: ""  },
        empresasIDs: { error: false, helperText: ""  }
    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});

    const [state, setState] = useState(cleanState);
    const [stateMultiple, setStateMultiple] = useState(cleanStateMultiple);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    const [errorToken, setErrorToken] = useState(null);
    const tokenError = useErrorToken(errorToken);

    let titleAccion = state.usuarioID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} usuario`;

    let nombreUsuarioRef = useRef(null);
    let emailRef = useRef(null);
    let passwordRef = useRef(null);
    let confirmPasswordRef = useRef(null);

    const acciones = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "usuarioID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert, asyncGet: getUsuario, setErrorToken
        })
    },{
        icono: BusinessIcon,
        color: warning,
        descripcion: "Asignar empresas",
        parametros: [{ campo: "usuarioID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState: setStateMultiple, setOpen, setAlert, asyncGet: getUsuario
        })
    },{
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "usuarioID" }, { campo: "nombreUsuario" }],
        disabled: { multiseleccion: true },
        onClick: ({ usuarioID, nombreUsuario = "seleccionado" }) => handelAlertEliminar({
            setAlert,
            mensaje: `al usuario ${nombreUsuario}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { usuarioID }, asyncDelete: deleteUsuario, setErrorToken
            })
        })
    }];

    const botones = [{
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "usuarioID",
        color: primary,
        title: "Usuarios",
        iconTable: <PeopleIcon />,
        headers: [
            { variable: "usuarioID", descripcion: "ID" },
            { variable: "nombreUsuario", descripcion: "Usuario" },
            { variable: "email", descripcion: "Email" },
            { variable: "nombreProveedor", descripcion: "Proveedor" },
            { variable: "estatus", descripcion: "Estatus" },
            { variable: "tipo", descripcion: "Tipo" },
            { variable: "fechaAlta", descripcion: "Fecha Alta" }
        ],
        responsiveTitle: ["usuarioID", "nombreUsuario", "email"],
        filter: [
            { campo: "nombreUsuario", placeholder: "Usuario" },
            { campo: "email", placeholder: "Email" },
            { campo: "nombreProveedor", placeholder: "Proveedor" },
            { campo: "estatus", placeholder: "Estatus" },
            { campo: "tipo", placeholder: "Tipo" }
        ],
        alineacion: [{ columnas: [0,4,5,6,7], alineacion: "center" }],
        formato: [{ columnas: ["fechaAlta"], tipo: "fecha" }],
        // rangoFechas: { ini: "fechaIni", fin: "fechaFin" },
    }

    const inputs = [{
        disabled,
        id: "proveedorID",
        value: state.proveedorID,
        error: errorState.proveedorID.error,
        success: state.proveedorID && !errorState.proveedorID.error ? true : undefined,
        helperText: errorState.proveedorID.helperText,
        title: "Proveedor *",
        placeholder: "Seleccionar el proveedor",
        tipo: "select",
        data: seleccionables.proveedores,
        onChange: ({target:{value}}) => setState({ ...state, proveedorID: value})
    },{
        disabled,
        id: "nombreUsuario",
        value: state.nombreUsuario,
        error: errorState.nombreUsuario.error,
        success: state.nombreUsuario && !errorState.nombreUsuario.error ? true : undefined,
        helperText: errorState.nombreUsuario.helperText,
        inputRef: nombreUsuarioRef,
        title: "Nombre del usuario *",
        placeholder: "Capture el nombre del usuario",
        grid: { md: 6, lg: 6 },
        onChange: ({target:{value}}) => handleChange(value, "nombreUsuario"),
        onKeyDown: (evt) => handleFocus(evt, emailRef)
    },{
        disabled: state.usuarioID ? true : disabled,
        id: "email",
        value: state.email,
        error: errorState.email.error,
        success: state.email && !errorState.email.error ? true : undefined,
        helperText: errorState.email.helperText,
        inputRef: emailRef,
        title: "Correo electrónico *",
        placeholder: "Capture el correo electrónico",
        grid: { md: 6, lg: 6 },
        onChange: ({target:{value}}) => handleChange(value, "email"),
        onKeyDown: (evt) => handleFocus(evt, passwordRef)
    },{
        disabled,
        id: "password",
        value: state.password,
        error: errorState.password.error,
        success: state.password && !errorState.password.error ? true : undefined,
        helperText: errorState.password.helperText,
        inputRef: passwordRef,
        title: `${state.usuarioID ? "Nueva c" : "C"}ontraseña ${state.usuarioID ? "" : "*"}`,
        placeholder: "Capture la contraseña",
        grid: { md: 6, lg: 6 },
        inputProps: { type: "password" },
        onChange: ({target:{value}}) => handleChange(value, "password"),
        onKeyDown: (evt) => handleFocus(evt, confirmPasswordRef)
    },{
        disabled,
        id: "confirmPassword",
        value: state.confirmPassword,
        error: errorState.confirmPassword.error,
        success: state.confirmPassword && !errorState.confirmPassword.error ? true : undefined,
        helperText: errorState.confirmPassword.helperText,
        inputRef: confirmPasswordRef,
        title: `Confirmar contraseña ${state.usuarioID ? "" : "*"}`,
        placeholder: "Capture de nuevo la contraseña",
        grid: { md: 6, lg: 6 },
        inputProps: { type: "password" },
        onChange: ({target:{value}}) => handleChange(value, "confirmPassword"),
        onKeyDown: () => { return; }
    },{
        disabled,
        id: "estatusID",
        value: state.estatusID,
        error: errorState.estatusID.error,
        success: state.estatusID && !errorState.estatusID.error ? true : undefined,
        helperText: errorState.estatusID.helperText,
        title: "Estatus *",
        placeholder: "Seleccionar el estatus",
        tipo: "select",
        data: seleccionables.estatus,
        grid: { md: 6, lg: 6 },
        onChange: ({target:{value}}) => setState({ ...state, estatusID: value})
    },{
        disabled,
        id: "tipoID",
        value: state.tipoID,
        error: errorState.tipoID.error,
        success: state.tipoID && !errorState.tipoID.error ? true : undefined,
        helperText: errorState.tipoID.helperText,
        title: "Tipo de usuario *",
        placeholder: "Seleccionar el tipo de usuario",
        tipo: "select",
        data: seleccionables.tipoUsuarios,
        grid: { md: 6, lg: 6 },
        onChange: ({target:{value}}) => setState({ ...state, tipoID: value})
    }];

    const inputEmpresas = [{
        disabled,
        id: "empresasIDs",
        value: stateMultiple.empresasIDs,
        error: errorState.empresasIDs.error,
        success: state.empresasIDs && !errorState.empresasIDs.error ? true : undefined,
        helperText: errorState.empresasIDs.helperText,
        title: "Asignar empresas *",
        placeholder: "Selecciona las empresas",
        tipo: "autocomplete",
        multiple: true,
        data: seleccionables.empresas,
        onChange: data => {
            const value = Array.isArray(data) && data.length > 0 ? data.map(({value}) => value) : [];
            setStateMultiple({ ...stateMultiple, empresasIDs: value})
        }
    }];

    const accionesFormulario = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: !stateMultiple.usuarioID && nombreUsuarioRef,
            mensajeCorrecto: stateMultiple.usuarioID ? "Se actualizaron las empresas asignadas" : `El usuario se ${state.usuarioID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: stateMultiple.usuarioID ? ftAsignar : ftGuardar, setErrorToken
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }){
        return handleErrorInputText({ cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState });
    }

    function handleValidaciones({ usuarioID, proveedorID, nombreUsuario, email, password, confirmPassword, estatusID, tipoID }) {

        let error;
        let validaciones = [{
            condicion: !rgxNumeros.test(proveedorID), keyError: "proveedorID",
            mensajeError: "Para que podamos funcionar bien necesitamos que le asignes un proveedor al usuario"
        }, {
            condicion: !nombreUsuario || trim(nombreUsuario) === "",
            ref: nombreUsuarioRef, keyError: "nombreUsuario",
            mensajeError: "Para que podamos funcionar bien necesitamos que le definas un nombre al usuario"
        }, {
            condicion: !rgxCorreo.test(email), ref: emailRef, keyError: "email",
            mensajeError: "El correo capturado lamentablemente no tiene un formato correcto"
        }, {
            condicion: (usuarioID && password && !rgxPassword.test(password)) || (!usuarioID && !rgxPassword.test(password)),
            ref: passwordRef, keyError: "password",
            mensajeError: "La estructura de la contraseña debe contener una letra mayúscula, una letra minúscula, un número, un caracter especial y de 8 a 30 caracteres"
        }, {
            condicion: password !== confirmPassword, ref: confirmPasswordRef, keyError: "confirmPassword",
            mensajeError: "La contraseña de confirmación no es igual a la contraseña capturada"
        }, {
            condicion: !rgxNumeros.test(estatusID), keyError: "estatusID",
            mensajeError: "Ya falta poco, solo es necesario definir un estatus al usuario"
        }, {
            condicion: !rgxNumeros.test(tipoID), keyError: "tipoID",
            mensajeError: "Ya casi, únicamente es necesario asignar un tipo al usuario"
        }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if(error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if(error) return error;

    }

    function ftAsignar() {
        async function ftAsignar() {

            let { usuarioID, empresasIDs } = stateMultiple;

            await asignarEmpresasUsuario({ usuarioID, empresasIDs }, token);

        }
        return ftAsignar();
    }

    function ftGuardar() {
        async function ftGuardar() {

            let { usuarioID, proveedorID, nombreUsuario, email, password, confirmPassword, estatusID, tipoID } = state;

            let error = await handleValidaciones({ usuarioID, proveedorID, nombreUsuario, email, password, confirmPassword, estatusID, tipoID });

            if(error) return error;

            if(usuarioID) await updateUsuario({
                input: { usuarioID, proveedorID, nombreUsuario: trim(nombreUsuario), email, password: !password ? null : password, estatusID, tipoID }
            }, token);
            else await addUsuario({
                input: { proveedorID, nombreUsuario: trim(nombreUsuario), email, password, estatusID, tipoID }
            }, token);

        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setStateMultiple({ ...cleanStateMultiple });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function ftEffect() {
        async function Effect() {
            try {

                const enEstatus = await EnumEstatus(token);

                let proveedores = await seleccionProveedores(token);
                let estatus = await selectEnumEstatus(token);
                let tipoUsuarios = await selectEnumTipoUsuarios(token);

                let empresas = await seleccionEmpresa({
                    estatusID: enEstatus.ACTIVO.toNumber()
                }, token);

                setSeleccionables({ proveedores, estatus, tipoUsuarios, empresas });

            } catch({message}) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
                setErrorToken(message);
            }
        } Effect();
    }

    useEffect(ftEffect, [actualizar]);

    return(!tokenError && <>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaUsuarios}
            token={token}
        />
        <FormularioModal
            open={open}
            title={stateMultiple.usuarioID ? "Asignar empresas" : titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={stateMultiple.usuarioID ? inputEmpresas : inputs}
            acciones={accionesFormulario}
            focus={!stateMultiple.usuarioID && nombreUsuarioRef}
        />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        /> }
    </>);

}

export default Usuarios;
