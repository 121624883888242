import React, { useState, useRef, useEffect } from 'react'
import { Validaciones } from '@renedelangel/helpers';

// Redux
import { useSelector } from "react-redux";

// Custom hooks
import { useErrorToken } from '../../hooks/errores';

// master components
import Tabla from '../../_layout/masterComponents/Tabla';
import FormularioModal from '../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import { NetworkError } from "../../_layout/genericComponents/Metodos";

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';

import { getEmpresa, tablaEmpresas } from '../../querys/Empresas/metodos';
import { selectEnumEstatus } from '../../querys/Enums/metodos'
import { addEmpresa, updateEmpresa, deleteEmpresa } from '../../mutations/Empresas/metodos';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar } from '../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../_layout/helpers/colores';

import { rgxNumeros, rgxRFC } from '../../helpers/regexp';

const { trim } = Validaciones;

function Empresas() {

    const { token } = useSelector(state => state.login);

    const cleanState = { empresaID: null, razonSocial: "", rfc: "", estatusID: "" };
    const cleanErrorState = {
        razonSocial: { error: false, helperText: ""  },
        rfc: { error: false, helperText: ""  },
        estatusID: { error: false, helperText: "" }
    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});

    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    const [errorToken, setErrorToken] = useState(null);
    const tokenError = useErrorToken(errorToken);

    let titleAccion = state.usuarioID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} empresa`;

    let razonSocialRef = useRef(null);
    let rfcRef = useRef(null);

    const acciones = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "empresaID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getEmpresa, setErrorToken
        })
    },{
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "empresaID" }, { campo: "razonSocial" }],
        disabled: { multiseleccion: true },
        onClick: ({ empresaID, razonSocial = "seleccionado" }) => handelAlertEliminar({
            setAlert,
            mensaje: `a la empresa ${razonSocial}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { empresaID }, asyncDelete: deleteEmpresa, setErrorToken
            })
        })
    }];

    const botones = [{
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "empresaID",
        color: primary,
        title: "Empresas",
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "empresaID", descripcion: "ID" },
            { variable: "razonSocial", descripcion: "Razón social" },
            { variable: "rfc", descripcion: "RFC" },
            { variable: "estatus", descripcion: "Estatus" },
            { variable: "fechaAlta", descripcion: "Fecha Alta" },
        ],
        responsiveTitle: ["empresaID", "razonSocial", "rfc"],
        filter: [
            { campo: "razonSocial" },
            { campo: "rfc" },
            { campo: "estatus" }
        ],
        alineacion: [{ columnas: [0,3,4], alineacion: "center" }],
        formato: [{ columnas: ["fechaAlta"], tipo: "fecha" }],
        // rangoFechas: { ini: "fechaIni", fin: "fechaFin" },
    }

    const inputs = [{
        disabled,
        id: "razonSocial",
        value: state.razonSocial,
        error: errorState.razonSocial.error,
        success: state.razonSocial && !errorState.razonSocial.error ? true : undefined,
        helperText: errorState.razonSocial.helperText,
        inputRef: razonSocialRef,
        title: "Razón social *",
        placeholder: "Capture el nombre de la empresa",
        onChange: ({target:{value}}) => handleChange(value, "razonSocial"),
        onKeyDown: (evt) => handleFocus(evt, rfcRef)
    },{
        disabled,
        id: "rfc",
        value: state.rfc,
        error: errorState.rfc.error,
        success: state.rfc && !errorState.rfc.error ? true : undefined,
        helperText: errorState.rfc.helperText,
        inputRef: rfcRef,
        title: "RFC *",
        placeholder: "Capture el RFC de la empresa",
        grid: { md: 6, lg: 6 },
        onChange: ({target:{value}}) => handleChange(value.toUpperCase(), "rfc"),
        onKeyDown: () => { return; }
    },{
        disabled,
        id: "estatusID",
        value: state.estatusID,
        error: errorState.estatusID.error,
        success: state.estatusID && !errorState.estatusID.error ? true : undefined,
        helperText: errorState.estatusID.helperText,
        title: "Estatus *",
        placeholder: "Seleccionar el estatus",
        tipo: "select",
        data: seleccionables.estatus,
        grid: { md: 6, lg: 6 },
        onChange: ({target:{value}}) => setState({ ...state, estatusID: value})
    }];

    const accionesFormulario = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: razonSocialRef,
            mensajeCorrecto: `La empresa se ${state.empresaID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
            setErrorToken
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }){
        return handleErrorInputText({ cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState });
    }

    function handleValidaciones({ razonSocial, rfc, estatusID }) {

        let error;
        let validaciones = [{
            condicion: !razonSocial || trim(razonSocial) === "",
            ref: razonSocialRef, keyError: "razonSocial",
            mensajeError: "Para que podamos funcionar bien necesitamos que le definas un nombre a la empresa"
        }, {
            condicion: !rgxRFC.test(rfc), ref: rfcRef, keyError: "rfc",
            mensajeError: "El RFC capturado lamentablemente no tiene un formato correcto"
        }, {
            condicion: !rgxNumeros.test(estatusID), keyError: "estatusID",
            mensajeError: "Ya falta poco, solo es necesario definir un estatus al usuario"
        }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if(error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if(error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { empresaID, razonSocial, rfc, estatusID } = state;

            let error = await handleValidaciones({ razonSocial, rfc, estatusID });

            if(error) return error;

            if(empresaID) await updateEmpresa({
                input: { empresaID, razonSocial: trim(razonSocial), rfc: rfc.toUpperCase(), estatusID }
            }, token);
            else await addEmpresa({
                input: { razonSocial: trim(razonSocial), rfc: rfc.toUpperCase(), estatusID }
            }, token);

        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function ftEffect() {
        async function effect() {
            try {

                let estatus = await selectEnumEstatus(token);
                setSeleccionables({ estatus });

            } catch({message}) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
                setErrorToken(message);
            }
        } effect();
    }

    useEffect(ftEffect, [actualizar]);

    return(!tokenError && <>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaEmpresas}
            token={token}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={razonSocialRef}
        />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        /> }
    </>);

}

export default Empresas;
