import React, { useState, useRef, useEffect } from 'react'
import Formulario from '../../_layout/genericComponents/Formulario';
// generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import { handleAlertGeneric, handleAlertMutationGeneric } from '../../_layout/helpers/handles';
import { urlServidor } from '../../helpers';
import { NetworkError } from "../../_layout/genericComponents/Metodos";
import { info, danger, success, primary } from "../../_layout/helpers/colores";
// Redux
import { useSelector } from 'react-redux';
//Querys
// import { seleccionEmpresa } from '../../../querys/Empresas';
// material-ui cons
import PrintIcon from '@material-ui/icons/Print';
import { seleccionTiposDocumento, seleccionDivisas, seleccionEstadoVigencia } from '../../querys/Documentos/metodos';
import { rgxRFC } from '../../helpers/regexp';


function Reportes() {

    const { token, usuario } = useSelector(state => state.login);


    const rfcEmisorRef = useRef(null); 
    const rfcReceptorRef = useRef(null); 

    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    const formato = "YYYY-MM-DD";
    
    const cleanState = {
        tipoDoctoID: null,
        tipoReporte: "",
        rfcEmisor: "",
        rfcReceptor: "",
        formatoReporte: "PDF",
        fechaInicio: null,
        fechaFin: null,
        divisa: "null",
        estadoVigencia: "null"

    }

    const cleanErrorState = {
        tipoDoctoID: { error: false, helperText: "" },
        tipoReporte: { error: false, helperText: "" },
        rfcEmisor: { error: false, helperText: "" },
        rfcReceptor: { error: false, helperText: "" },
        fechaInicio: { error: false, helperText: "" },
        fechaFin: { error: false, helperText: "" }

    }



    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [disabled, setDisabled] = useState(false);
    const [seleccionables, setSeleccionables] = useState({});
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    

    const listFormatoReporte = [{
        label: 'PDF',
        value: 'PDF'
    },
    {
        label: 'EXCEL',
        value: 'Excel'
    }
    ];


    const inputs = [{
        id: "IDTRF",
        tipo: "fieldset",
        leyenda: `Criterios de Selección de Reporte: ${state.tipoDoctoID !== 0 ? state.tipoReporte : ""}`,
        inputs: [
        {
            disabled,
            id: "tipoDoctoID",
            value: state.tipoDoctoID,
            grid: { md: 4, lg: 4 },
            error: errorState.tipoDoctoID.error,
            success: state.tipoDoctoID && !errorState.tipoDoctoID.error ? true : undefined,
            helperText: errorState.tipoDoctoID.helperText,
            title: "Tipo de Documento",
            placeholder: "Seleccionar...",
            tipo: "autocomplete",
            data: seleccionables.tiposDocumento,
            onChange: (target) => {
                target && setState({ ...state, tipoDoctoID: target.value, tipoReporte: target.label });
            }
        },
        {
            disabled,
            id: "rfcEmisor",
            value: state.rfcEmisor,
            error: errorState.rfcEmisor.error,
            success: state.rfcEmisor && !errorState.rfcEmisor.error ? true : undefined,
            helperText: errorState.rfcEmisor.helperText,
            inputRef: rfcEmisorRef,
            grid: { md: 2, lg: 2 },
            title: "RFC Emisor",
            placeholder: "Capture el RFC Emisor",
            onChange: ({ target: { value } }) => {
                handleChange(value.toUpperCase(), "rfcEmisor")
            },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, rfcEmisor: { error: state.rfcEmisor === "" ? false : (!rgxRFC.test(state.rfcEmisor) ? true : false), helperText: state.rfcEmisor === "" ? "" : (!rgxRFC.test(state.rfcEmisor) ? 'RFC Equivocado' :'')} })
            },
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "rfcReceptor",
            value: state.rfcReceptor,
            error: errorState.rfcReceptor.error,
            success: state.rfcReceptor && !errorState.rfcReceptor.error ? true : undefined,
            helperText: errorState.rfcReceptor.helperText,
            inputRef: rfcReceptorRef,
            grid: { md: 2, lg: 2 },
            title: "RFC Receptor",
            placeholder: "Capture el RFC Receptor",
            onChange: ({ target: { value } }) => {
                    handleChange(value.toUpperCase(), "rfcReceptor")
            },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, rfcReceptor: { error: state.rfcReceptor === "" ? false :  (!rgxRFC.test(state.rfcReceptor) ? true : false), helperText: state.rfcReceptor === "" ? "" : (!rgxRFC.test(state.rfcReceptor) ? 'RFC Equivocado' :'')} })
            },
            onKeyDown: () => { return; }
        },
        {
            id: "fechaInicio",
            value: state.fechaInicio,
            grid: { md: 2, lg: 2 },
            error: errorState.fechaInicio.error,
            success: state.fechaInicio && !errorState.fechaInicio.error ? true : undefined,
            helperText: errorState.fechaInicio.helperText,
            placeholder: "Fecha Inicio",
            tipo: "datetimepicker",
            onlyDate: true,
            onChange: (value) => typeof(value) === 'object' ? handleChange(value.format(formato), "fechaInicio") : handleChange(null, "fechaInicio")
        },
        {
            id: "fechaFin",
            value: state.fechaFin,
            grid: { md: 2, lg: 2 },
            error: errorState.fechaFin.error,
            success: state.fechaFin && !errorState.fechaFin.error ? true : undefined,
            helperText: errorState.fechaFin.helperText,
            placeholder: "Fecha Fin",
            tipo: "datetimepicker",
            onlyDate: true,
            onChange: (value) => typeof(value) === 'object' ? handleChange(value.format(formato), "fechaFin") : handleChange(null, "fechaFin")
        },
        {
            value: state.divisa,
            grid: { md: 4, lg: 4 },
            title: "Divisas",
            tipo: "select",
            data: seleccionables.divisas,
            onChange: ({ target: { value } }) => setState({ ...state, divisa: value }),
        },
        {
            value: state.estadoVigencia,
            grid: { md: 4, lg: 4 },
            title: "Estado de Vigencia",
            tipo: "select",
            data: seleccionables.estadosVigencias,
            onChange: ({ target: { value } }) => setState({ ...state, estadoVigencia: value }),
        },
        {
            disabled,
            id: "formatoReporte",
            value: state.formatoReporte,
            grid: { md: 3, lg: 3 },
            title: "Formato de Reporte",
            tipo: "select",
            data: listFormatoReporte,
            onChange: ({ target: { value } }) => setState({ ...state, formatoReporte: value }),
        }
        ]
    }
    ];

    
    function handleChange(value, key) { setState({...state, [key]: value }); }

    const accionesFormulario = [{
        loader,
        disabled,
        icono: PrintIcon,
        color: primary,
        descripcion: "Generar Reporte",
        onClick: () => state.tipoDoctoID ?
            handleAlertGeneric({
                setAlert,
                onCancel: () => setAlert(null),
                mensaje: {
                    title: `Generar Reporte`,
                    descripcion: `¿Deseas generar y descargar el reporte de ${state.tipoReporte}?`,
                    tipo: "warning",
                    msjConfirmacion: "Aceptar",
                    msjCancelacion: "Cancelar"
                },
                onConfirm: () => handleAlertMutationGeneric({
                    token,
                    setAlert,
                    setActualizar,
                    actualizar,
                    asyncMutation: GenerarPDF,
                    mensajes: {
                        msjEspera: {
                            title: "Espere...",
                            descripcion: `Generando el reporte de ${state.tipoReporte} `,
                            tipo: success
                        },
                        msjCorrecto: {
                            title: "Se generó con éxito",
                            descripcion: ` Se descargó el reporte de ${state.tipoReporte}`,
                            msjConfirmacion: "Aceptar"
                        }
                    }
                })
            }) : setAlert({
                descripcion: "Debe seleccionar un reporte",
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            })
    }
    ];


    //funcion para generar el PDF con sus parámetros correspondientes
    function GenerarPDF() {    
    async function GenerarPDF() {
        
        let { tipoDoctoID, tipoReporte, divisa, estadoVigencia, formatoReporte, fechaInicio, fechaFin, rfcEmisor, rfcReceptor } = state;
/*         let nombreReporte = "reporteGenCatalogos";    */   
        let nombreReporte = "reporteDocumentos";
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");
        let formdata = new FormData();
        
        formdata.append("tipoDoctoID", tipoDoctoID);
        formdata.append("nombreReporte", nombreReporte);
        formdata.append("tipoReporte", tipoReporte);
        formdata.append("divisa", divisa);
        formdata.append("estadoVigencia", estadoVigencia);
        formdata.append("formatoReporte", formatoReporte);
        formdata.append("fechaInicio", fechaInicio);
        formdata.append("fechaFin", fechaFin);
        formdata.append("rfcEmisor", rfcEmisor);
        formdata.append("rfcReceptor", rfcReceptor);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        await fetch(`${urlServidor}/service/reportes/ReportesGenCatalogos`, requestOptions)
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                showFile(result, `${tipoReporte}${state.formatoReporte === "PDF" ? ".pdf" : ".xlsx"}`);
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });

    }
    return GenerarPDF();
}

    useEffect(() => {
        async function ftEffect() {
            try {

                let tiposDocumento = await seleccionTiposDocumento({ filtro: {} }, token);
                tiposDocumento.unshift({ label: 'MOSTRAR TODOS', value: "null" })
                let divisas = await seleccionDivisas({ filtro: {} }, token);
                divisas.unshift({ label: 'MOSTRAR TODAS', value: "null" })
                let estadosVigencias = await seleccionEstadoVigencia({ filtro: {} }, token);
                estadosVigencias.unshift({ label: 'MOSTRAR TODOS', value: "null" })
                

                setSeleccionables(seleccionables => ({
                    ...seleccionables,  
                    tiposDocumento,
                    divisas,
                    estadosVigencias
                }))

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(
                        message
                    )}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        }
        ftEffect();
    }, []);

    return (<>

        <Formulario 
            color = {primary}
            title = {"Reportes"}
            header={true}
            inputs={inputs}
            acciones={accionesFormulario}
        />

        {
            alert && < SweetAlert
                title={alert.title}
                descripcion={alert.descripcion}
                tipo={alert.tipo}
                msjConfirmacion={alert.msjConfirmacion}
                msjCancelacion={alert.msjCancelacion}
                onConfirm={alert.onConfirm}
                showConfirm={alert.showConfirm}
                showCancel={alert.showCancel}
                onCancel={
                    () => setAlert(null)
                }
            />
        } </>
    );
}

export default Reportes;
