import React from 'react';

import Select, { createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';

// import { Validaciones } from '@renedelangel/helpers';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from "@material-ui/core/FormHelperText";

import classNames from "classnames";

import styles from '../../../assets/jss/material-dashboard-pro-react/customSelectStyle.js';
import { colourStyles, themeStyles } from './jss/autocomplete';

// import './autocomplete.css';
// const { trim } = Validaciones;

const useStyles = makeStyles(styles);

export default function AutocompleteCustom(props) {

    const classes = useStyles();

    let { id, title, data, value, placeholder, multiple, disabled, onChange, error, success, helperText, asyncData, /*asyncGetOptions,*/ autocompleteProps = {}, withoutHash = false, inputRef  } = props/*, getOptions = {}*/;

    // if(asyncGetOptions) getOptions = { getOptions: (query) => asyncGetOptions(query) };

    // const opciones = Array.isArray(data) && data.length > 0 ? data.map(({label:name, value}) => verificar(name, value)) : [{ value: "", name: "No se encontraron registros" }];

    const opciones = Array.isArray(data) && data.length > 0 ? data : [{ value: null, label: "No se encontraron registros" }];

    let hashOptions = {};
    if(!withoutHash && Array.isArray(data) && data.length > 0) data.forEach(info => {
        hashOptions = { ...hashOptions, [info.value]: { ...info } };
    });

    const filterConfig = { ignoreAccents: true, matchFrom: 'any' };

    let helpTextClasses = classNames({
        [classes.labelRootError]: error,
        [classes.labelRootSuccess]: success && !error
    });

    return (<FormControl fullWidth >
        { title && <label className={helpTextClasses}>{title}</label> }
        <div>
            {
                asyncData ? <AsyncSelect
                    cacheOptions
                    defaultOptions
                    isMulti={multiple}
                    isSearchable={true}
                    value={withoutHash ? value : (multiple ? (Array.isArray(value) ? value.map(v => hashOptions[v]) : []) : (value ? hashOptions[value] : null))}
                    loadOptions={asyncData}
                    placeholder={placeholder}
                    isDisabled={disabled}
                    onChange={value => onChange(value)}
                    isClearable={true}
                    filterOption={createFilter(filterConfig)}
                    styles={colourStyles({ error, success })}
                    theme={themeStyles}
                    { ...autocompleteProps }
                /> : <Select
                    ref={inputRef ? inputRef : undefined}
                    isMulti={multiple}
                    isSearchable={true}
                    value={withoutHash ? value : (multiple ? (Array.isArray(value) ? value.map(v => hashOptions[v]) : []) : (value ? hashOptions[value] : null))}
                    options={opciones}
                    placeholder={placeholder}
                    isDisabled={disabled}
                    onChange={(value, actions) => onChange(value, actions)}
                    isClearable={true}
                    filterOption={createFilter(filterConfig)}
                    styles={colourStyles({ error, success })}
                    theme={themeStyles}
                    { ...autocompleteProps }
                />
            }
        </div>
        {error && helperText !== undefined ? (
            <FormHelperText id={id + "-text"} className={helpTextClasses}>
                {helperText}
            </FormHelperText>
        ) : null}
    </FormControl>);
}
