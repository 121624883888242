import React from 'react'

import Login from '../../_layout/masterComponents/Login';

import { setToken, setUsuario } from '../../redux/actions/loginAction';

import loginFondo from '../../_layout/assets/img/banner_fondo_descargaxml.png';

import { isUsername, getLogin, getNewPassword } from '../../querys/Login/metodos';
import { seedBackend } from '../../helpers';

function IniciarSesion() {

    const title = "Descarga masiva de comprobantes digitales";

    return (
        <Login
            title={title}
            isUsername={isUsername}
            getLogin={getLogin}
            getNewPassword={getNewPassword}
            setToken={setToken}
            setUsuario={setUsuario}
            loginFondo={loginFondo}
            seedBackend={seedBackend}
        />
    )
}

export default IniciarSesion;
