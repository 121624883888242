import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';
import { GET_EMPRESA, GET_ALL_EMPRESA, TABLA_EMPRESA } from '../index';

export const getEmpresa = async (variables, token) => {
    try {

        let { getEmpresa } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_EMPRESA
        });

        return getEmpresa;

    } catch({message}) { throw new Error(message); }
}

export const getAllEmpresa = async (variables, token) => {
    try {

        let { getAllEmpresa } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_EMPRESA
        });

        return getAllEmpresa;

    } catch({message}) { throw new Error(message); }
}

export const seleccionEmpresa = async (variables, token) => {
    try {

        let allEmpresas = await getAllEmpresa(variables, token);

        if(!Array.isArray(allEmpresas)) throw new Error("No se encontraron registros");
    
        let listado = allEmpresas.map(({ empresaID, razonSocial,  ...rest }) => {               
                        return {
                            ...rest,
                            value: Number(empresaID),
                            label: razonSocial
                        }
                    });
      
        return listado;

    } catch({message:label}) {
        return [{
            label,
            value: "",
            disabled: true
        }];
    }
}

export const tablaEmpresas = async (variables, token) => {
    try {

        let { filterEmpresas } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_EMPRESA
        });

        return filterEmpresas;

    } catch({message}) { throw new Error(message); }
}
