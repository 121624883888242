
export const TABLA_PROVEEDORES = `
    query filterProveedores($filtro: InputFilterProvedor!) {
        filterProveedores(input: $filtro) {
            registros
            paginas
            listado {
                proveedorID
                nombre
                alias
                logotipo
            }
        }
    }
`;

export const GET_PROVEEDORES = `
    query getProveedores($proveedorID: ID!) {
        getProveedor(id: $proveedorID) {
            proveedorID
            nombre
            alias
            logotipo
            url
            emailID
            emailService
            host
            port
            user
            pass
            from
        }
    }
`;

export const GET_ALL_PROVEEDORES = `
    query getAllProveedor {
        getAllProveedor {
            proveedorID
            nombre
        }
    }
`;

export const SEND_EMAIL_PROVEEDOR = `
    query sendEmailProveedor($proveedorID: ID!){
        sendEmailProveedor(id: $proveedorID)
    }
`;
