import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';
import { ADD_EMPRESA, UPDATE_EMPRESA, DELETE_EMPRESA } from '../index';

export const addEmpresa = async (variables, token) => {
    try {

        let { addEmpresa } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_EMPRESA
        });

        return addEmpresa;

    } catch({message}) { throw new Error(message); }
};

export const updateEmpresa = async (variables, token) => {
    try {

        let { updateEmpresa } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_EMPRESA
        });

        return updateEmpresa;

    } catch({message}) { throw new Error(message); }
};

export const deleteEmpresa = async (variables, token) => {
    try {

        let { deleteEmpresa } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: DELETE_EMPRESA
        });

        return deleteEmpresa;

    } catch({message}) { throw new Error(message); }
};
