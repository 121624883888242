import React from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveIcon from '@material-ui/icons/Remove';

import RowEditable from './componentes/Editable';

// Helpers
import { Fechas, Utilidades } from "@renedelangel/helpers";

const { DateToSpanishDate } = Fechas;
const { NumberToMoney } = Utilidades;

export default function RowComponentCustom ({ valor, tipo, token, url, onlyDate = false, simbolo = "$", callback = () => console.log, id, onEnter, row, parametros, onDobleClic }) {

    let componente = "";

    switch (tipo) {
        case "fecha":
            componente = valor ? DateToSpanishDate(valor, false, onlyDate) : "";
            break;
        case "imagen":
            componente = valor ? <img src={`${url}${valor}?token=${token}`} alt={valor} title={valor} style={{ maxHeight: "100px", width: "auto" }} /> : "";
            break;
        case "estatus":
            componente = valor ? <CheckCircleIcon /> : <RemoveIcon />;
            break;
        case "moneda":
            componente = valor ? NumberToMoney(valor, simbolo) : "";
            break;
        case "custom":
            componente = callback(valor, row);
            break;
        case "editable":
            componente = <RowEditable id={id} valor={valor} row={row} onEnter={onEnter} onDobleClic={onDobleClic} parametros={parametros} />
            break;
        default:
            componente = `${valor}`;
            break;
    }

    return componente;
}
