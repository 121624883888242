
export const GET_EMPRESA = `
    query getEmpresa($empresaID: ID!) {
        getEmpresa(id: $empresaID) {
            empresaID
            razonSocial
            rfc
            estatusID
        }
    }
`;

export const GET_ALL_EMPRESA = `
    query getAllEmpresa($estatusID: ID) {
        getAllEmpresa(estatusID: $estatusID) {
            empresaID
            razonSocial
            rfc
            estatusID
        }
    }
`;

export const TABLA_EMPRESA = `
    query filterEmpresa($filtro: InputFilterEmpresa!) {
        filterEmpresas(input: $filtro) {
            registros
            paginas
            listado {
                empresaID
                razonSocial
                rfc
                estatus
                fechaAlta
            }
        }
    }
`;
