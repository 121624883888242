/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';

// core components
import AdminNavbarLinks from '../Header/componentes/Links';

import sidebarStyle from '../../assets/jss/material-dashboard-pro-react/components/sidebarStyle.js';

import avatar from '../../assets/img/faces/avatar.jpg';

let ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
	sidebarWrapper = React.createRef();
	componentDidMount() {
		if (navigator.platform.indexOf('Win') > -1) {
			ps = new PerfectScrollbar(this.sidebarWrapper.current, {
				suppressScrollX: true,
				suppressScrollY: false
			});
		}
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf('Win') > -1) {
			ps.destroy();
		}
	}
	render() {
		const { className, user, headerLinks, links } = this.props;
		return (
			<div className={className} ref={this.sidebarWrapper}>
				{user}
				{headerLinks}
				{links}
			</div>
		);
	}
}

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openAvatar: false,
			miniActive: true,
			...this.getCollapseStates(props.routes)
		};
	}
	mainPanel = React.createRef();
	// this creates the intial state of this component based on the collapse routes
	// that it gets through this.props.routes
	getCollapseStates = (routes) => {
		let initialState = {};
		routes.map((prop) => {
			if (prop.collapse) {
				initialState = {
					[prop.state]: this.getCollapseInitialState(prop.views),
					...this.getCollapseStates(prop.views),
					...initialState
				};
			}
			return null;
		});
		return initialState;
	};
	// this verifies if any of the collapses should be default opened on a rerender of this component
	// for example, on the refresh of the page,
	// while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
	getCollapseInitialState(routes) {
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
				return true;
			} else if (window.location.href.indexOf(routes[i].path) !== -1) {
				return true;
			}
		}
		return false;
	}
	// verifies if routeName is the one active (in browser input)
	activeRoute = (routeName) => {
		return window.location.href.indexOf(routeName) > -1 ? 'active' : '';
	};
	openCollapse(collapse) {
		var st = {};
		st[collapse] = !this.state[collapse];
		this.setState(st);
	}
	// this function creates the links and collapses that appear in the sidebar (left menu)
	createLinks = (routes) => {
		const { classes, color, rtlActive } = this.props;
		return routes.map((prop, key) => {
			if (prop.redirect || prop.hide) return null;
			if (prop.collapse) {
				var st = {};
				st[prop['state']] = !this.state[prop.state];
				const navLinkClasses =
					classes.itemLink +
					' ' +
					cx({
						[' ' + classes.collapseActive]: this.getCollapseInitialState(prop.views)
					});
				const itemText =
					classes.itemText +
					' ' +
					cx({
						[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
						[classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
						[classes.itemTextRTL]: rtlActive
					});
				const collapseItemText =
					classes.collapseItemText +
					' ' +
					cx({
						[classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
						[classes.collapseItemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
						[classes.collapseItemTextRTL]: rtlActive
					});
				const itemIcon =
					classes.itemIcon +
					' ' +
					cx({
						[classes.itemIconRTL]: rtlActive
					});
				const caret =
					classes.caret +
					' ' +
					cx({
						[classes.caretRTL]: rtlActive
					});
				const collapseItemMini =
					classes.collapseItemMini +
					' ' +
					cx({
						[classes.collapseItemMiniRTL]: rtlActive
					});
				return (
					<ListItem
						key={key}
						className={cx(
							{ [classes.item]: prop.icon !== undefined },
							{ [classes.collapseItem]: prop.icon === undefined }
						)}
					>
						<NavLink
							to={'#'}
							className={navLinkClasses}
							onClick={(e) => {
								e.preventDefault();
								this.setState(st);
							}}
						>
							{prop.icon !== undefined ? typeof prop.icon === 'string' ? (
								<Icon className={itemIcon}>{prop.icon}</Icon>
							) : (
								<prop.icon className={itemIcon} />
							) : (
								<span className={collapseItemMini}>{rtlActive ? prop.rtlMini : prop.mini}</span>
							)}
							<ListItemText
								primary={rtlActive ? prop.rtlName : prop.name}
								secondary={
									<b className={caret + ' ' + (this.state[prop.state] ? classes.caretActive : '')} />
								}
								disableTypography={true}
								className={cx(
									{ [itemText]: prop.icon !== undefined },
									{ [collapseItemText]: prop.icon === undefined }
								)}
							/>
						</NavLink>
						<Collapse in={this.state[prop.state]} unmountOnExit>
							<List className={classes.list + ' ' + classes.collapseList}>
								{this.createLinks(prop.views)}
							</List>
						</Collapse>
					</ListItem>
				);
			}
			const innerNavLinkClasses =
				classes.collapseItemLink +
				' ' +
				cx({
					[' ' + classes[color]]: this.activeRoute(prop.path)
				});
			const collapseItemMini =
				classes.collapseItemMini +
				' ' +
				cx({
					[classes.collapseItemMiniRTL]: rtlActive
				});
			const navLinkClasses =
				classes.itemLink +
				' ' +
				cx({
					[' ' + classes[color]]: this.activeRoute(prop.path)
				});
			const itemText =
				classes.itemText +
				' ' +
				cx({
					[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
					[classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
					[classes.itemTextRTL]: rtlActive
				});
			const collapseItemText =
				classes.collapseItemText +
				' ' +
				cx({
					[classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
					[classes.collapseItemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
					[classes.collapseItemTextRTL]: rtlActive
				});
			const itemIcon =
				classes.itemIcon +
				' ' +
				cx({
					[classes.itemIconRTL]: rtlActive
				});
			return (
				<ListItem
					key={key}
					className={cx(
						{ [classes.item]: prop.icon !== undefined },
						{ [classes.collapseItem]: prop.icon === undefined }
					)}
				>
					<NavLink
						to={prop.layout + prop.path}
						className={cx(
							{ [navLinkClasses]: prop.icon !== undefined },
							{ [innerNavLinkClasses]: prop.icon === undefined }
						)}
					>
						{prop.icon !== undefined ? typeof prop.icon === 'string' ? (
							<Icon className={itemIcon}>{prop.icon}</Icon>
						) : (
							<prop.icon className={itemIcon} />
						) : (
							<span className={collapseItemMini}>{rtlActive ? prop.rtlMini : prop.mini}</span>
						)}
						<ListItemText
							primary={rtlActive ? prop.rtlName : prop.name}
							disableTypography={true}
							className={cx(
								{ [itemText]: prop.icon !== undefined },
								{ [collapseItemText]: prop.icon === undefined }
							)}
						/>
					</NavLink>
				</ListItem>
			);
		});
	};
	render() {
        const { classes, logo, image, logoText, logoLink, routes, bgColor, rtlActive, targetLogoLink = "_self", userMenu = {} } = this.props;

		const itemText =
			classes.itemText +
			' ' +
			cx({
				[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
				[classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.itemTextRTL]: rtlActive
			});
		const collapseItemText =
			classes.collapseItemText +
			' ' +
			cx({
				[classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
				[classes.collapseItemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.collapseItemTextRTL]: rtlActive
			});
		const userWrapperClass =
			classes.user +
			' ' +
			cx({
				[classes.whiteAfter]: bgColor === 'white'
			});
		const caret =
			classes.caret +
			' ' +
			cx({
				[classes.caretRTL]: rtlActive
			});
		const collapseItemMini =
			classes.collapseItemMini +
			' ' +
			cx({
				[classes.collapseItemMiniRTL]: rtlActive
			});
		const photo =
			classes.photo +
			' ' +
			cx({
				[classes.photoRTL]: rtlActive
            });

        let { fotoPerfil, nombre, opciones = [] } = userMenu;
        let nombreUsuario = nombre ? nombre.split(" ") : "";
        if(nombreUsuario.length === 1) nombre = nombreUsuario[0];
        else {
            let nombreCompuesto = `${nombreUsuario[0]} ${nombreUsuario[1]}`;
            if(nombreCompuesto.length > 20) nombre = `${nombreCompuesto.substring(0, 17)}...`;
            else nombre = nombreCompuesto;
        }

		const user = ( (nombre && fotoPerfil) && <div className={userWrapperClass}>
				<div className={photo}>
					{ typeof fotoPerfil === "string" ? <img src={avatar} className={classes.avatarImg} alt="..." /> : fotoPerfil }
				</div>
				<List className={classes.list}>
					<ListItem className={classes.item + ' ' + classes.userItem}>
						<NavLink
							to={'#'}
							className={classes.itemLink + ' ' + classes.userCollapseButton}
							onClick={(Array.isArray(opciones) && opciones.length > 0) ? () => this.openCollapse('openAvatar') : () => { return; }}
						>
							<ListItemText
								primary={nombre}
								secondary={
									(Array.isArray(opciones) && opciones.length > 0) && <b
										className={
											caret +
											' ' +
											classes.userCaret +
											' ' +
											(this.state.openAvatar ? classes.caretActive : '')
										}
									/>
								}
								disableTypography={true}
								className={itemText + ' ' + classes.userItemText}
							/>
						</NavLink>
						{
                            (Array.isArray(opciones) && opciones.length > 0) && <Collapse in={this.state.openAvatar} unmountOnExit>
                                <List className={classes.list + ' ' + classes.collapseList}>
                                    {
                                        opciones.map(({alias, descripcion = "Opción", ruta}, index) => (<ListItem key={index} className={classes.collapseItem}>
                                            <NavLink to={ruta ? ruta : "#"} className={classes.itemLink + ' ' + classes.userCollapseLinks}>
                                                <span className={collapseItemMini}>{alias ? alias : descripcion.substring(0,1).toUpperCase()}</span>
                                                <ListItemText
                                                    primary={descripcion}
                                                    disableTypography={true}
                                                    className={collapseItemText}
                                                />
                                            </NavLink>
                                        </ListItem>))
                                    }
                                </List>
                            </Collapse>
                        }
					</ListItem>
				</List>
			</div>
		);
		var links = <List className={classes.list}>{this.createLinks(routes)}</List>;

		const logoNormal =
			classes.logoNormal +
			' ' +
			cx({
				[classes.logoNormalSidebarMini]: this.props.miniActive && this.state.miniActive,
				[classes.logoNormalSidebarMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.logoNormalRTL]: rtlActive
			});
		const logoMini =
			classes.logoMini +
			' ' +
			cx({
				[classes.logoMiniRTL]: rtlActive
			});
		const logoClasses =
			classes.logo +
			' ' +
			cx({
				[classes.whiteAfter]: bgColor === 'white'
            });

        const enlaceLogotipo = logoLink ? logoLink : "#";
        const brandText = logoText && logoText.length > 15 ? `${logoText.substring(0, 12)}...` : logoText;

		const brand = (
			<div className={logoClasses}>
				<a href={enlaceLogotipo} target={targetLogoLink} className={logoMini}>
                    { logo && typeof logo === "string" ? <img src={logo} alt="logo" className={classes.img} /> : <LabelImportantIcon /> }
				</a>
				<a href={enlaceLogotipo} target={targetLogoLink} title={logoText} className={logoNormal}>
					{brandText}
				</a>
			</div>
		);
		const drawerPaper =
			classes.drawerPaper +
			' ' +
			cx({
				[classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
				[classes.drawerPaperRTL]: rtlActive
			});
		const sidebarWrapper =
			classes.sidebarWrapper +
			' ' +
			cx({
				[classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
				[classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1
			});
		return (
			<div ref={this.mainPanel}>
				<Hidden mdUp implementation="css">
					<Drawer
						variant="temporary"
						anchor={rtlActive ? 'left' : 'right'}
						open={this.props.open}
						classes={{
							paper: drawerPaper + ' ' + classes[bgColor + 'Background']
						}}
						onClose={this.props.handleDrawerToggle}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						{brand}
						<SidebarWrapper
							className={sidebarWrapper}
							user={user}
							headerLinks={<AdminNavbarLinks rtlActive={rtlActive} />}
							links={links}
						/>
						{image !== undefined ? (
							<div className={classes.background} style={{ backgroundImage: 'url(' + image + ')' }} />
						) : null}
					</Drawer>
				</Hidden>
				<Hidden smDown implementation="css">
					<Drawer
						onMouseOver={() => this.setState({ miniActive: false })}
						onMouseOut={() => this.setState({ miniActive: true })}
						anchor={rtlActive ? 'right' : 'left'}
						variant="permanent"
						open
						classes={{
							paper: drawerPaper + ' ' + classes[bgColor + 'Background']
						}}
					>
						{brand}
						<SidebarWrapper className={sidebarWrapper} user={user} links={links} />
						{image !== undefined ? (
							<div className={classes.background} style={{ backgroundImage: 'url(' + image + ')' }} />
						) : null}
					</Drawer>
				</Hidden>
			</div>
		);
	}
}

Sidebar.defaultProps = {
	bgColor: 'blue'
};

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	bgColor: PropTypes.oneOf([ 'white', 'black', 'blue' ]),
	rtlActive: PropTypes.bool,
	color: PropTypes.oneOf([ 'white', 'red', 'orange', 'green', 'blue', 'purple', 'rose' ]),
	targetLogoLink: PropTypes.oneOf([ '_blank', '_self', '_parent', '_top' ]),
	logo: PropTypes.string,
	logoText: PropTypes.string,
	image: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	miniActive: PropTypes.bool,
	open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    userMenu: PropTypes.object
};

SidebarWrapper.propTypes = {
	className: PropTypes.string,
	user: PropTypes.object,
	headerLinks: PropTypes.object,
	links: PropTypes.object
};

export default withStyles(sidebarStyle)(Sidebar);
