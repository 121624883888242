
export const IS_USERNAME = `
    query isUsername($username: String!) {
        isUsername(username: $username)
    }
`;

export const GET_LOGIN = `
    query getLogin($username: String!, $password: String!) {
        getLogin(username: $username, password: $password)
    }
`;

export const IS_TOKEN = `
    query isToken {
        isToken
    }
`;

export const GET_NEWPASSWORD = `
    query getNewPassword($username: String!) {
        getNewPassword(username: $username)
    }
`;
